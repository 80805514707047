import React, { Component } from 'react';
import styled from 'styled-components';
// import { FiGrid as GridIcon } from '@react-icons/all-files/fi/FiGrid'
import GridIcon from './tools.svg';
import { FiX as CloseIcon } from '@react-icons/all-files/fi/FiX';
import ChatIcon from '/assets/syncs/chat.svg';
import NotesIcon from '/assets/syncs/notes2.svg';
import Presenter from '/assets/syncs/pdfPresenter.svg';
import Todo from '/assets/syncs/todo.svg';
import Transcription from '/assets/syncs/transcription.svg';
import FilesIcon from '/assets/syncs/files.svg';
import TicTacToeIcon from '/assets/syncs/tictactoe.svg';
import WhiteboardIcon from '/assets/syncs/whiteboard.svg';
import Links from '/assets/syncs/links.svg';
import Images from '/assets/syncs/images.svg';
import FloatingLinks from './FloatingWIdgets/Links';
import FloatingNotes from './FloatingWIdgets/Notes';
import FloatingImages from './FloatingWIdgets/Images';
import { WidgetActions } from '/stores/MainStore';
import FloatingChat from './FloatingWIdgets/Chat';
import Sagas from '../../../helpers/Sagas';

import { GlobalState } from 'reflux';
import CustomTip from '../../../helpers/CustomTip';
import { isMobile } from '../../../calling/_helpers';
import ChatV2 from './FloatingWIdgets/ChatV2/ChatV2';
import NewChat from './FloatingWIdgets/ChatV2/NewChat';
import { getUnseenMessage } from './FloatingWIdgets/ChatV2/utils';
import { CallingInstanceState } from '../../../calling/types';
import { MeetingControlBtnWrapper } from './KeynoteWidgets/StyledComponents';

import Sharedb from 'sharedb/lib/client';
import ReconnectingWebSocket from 'reconnecting-websocket';

const Connection = Sharedb.Connection;
const shareDbsocket = new ReconnectingWebSocket(process.env.REACT_APP_SHAREDB_SOCKET_URL || 'wss://localhost:8080');
const connection = new Connection(shareDbsocket);

class FloatingWidgets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFloat: false,
      activeSync: '',
      activeDisplayName: '',
      widgets: {},
      unreadMessages: 0,
    };

    this._updateActiveSync = this._updateActiveSync.bind(this);
    this._sendNotification = this._sendNotification.bind(this);
    this._activeSync = this._activeSync.bind(this);
    this._updateSelf = this._updateSelf.bind(this);
    this._addLink = this._addLink.bind(this);
    this._removeLink = this._removeLink.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showChat !== this.props.showChat && this.props.showChat !== false) {
      this.setState({
        activeSync: 'chatV2',
        activeDisplayName: 'chatV2',
      });
    }
  }

  _updateActiveSync(data) {
    //check if chatv2 close

    this.setState({ showFloat: false }, () => {
      setTimeout(() => {
        if (data.key === 'chatV2') {
          console.log('chat opened');
          this.setState({ unreadMessages: 0 });
          console.log(this.doc ? 'true' : 'false', this.doc);
          if (this.doc) this.doc.unsubscribe((err) => console.log('unsubscribe callback', err));
        } else {
          console.log('chat closed');
          this.subscribeToChat();
        }
        switch (data.key) {
          case 'pdfPresenter':
            WidgetActions.SetCurrentWidget({ currentWidget: 'pdfpresenter', localPush: true });
            break;
          case 'todo':
            WidgetActions.SetCurrentWidget({ currentWidget: 'todo', localPush: true });
            break;
          case 'transcription':
            WidgetActions.SetCurrentWidget({ currentWidget: 'transcription', localPush: true });
            break;
          case 'files':
            WidgetActions.SetCurrentWidget({ currentWidget: 'files', localPush: true });
            break;

          default:
            this.setState((prevState) => ({
              ...prevState,
              activeSync: data && data.key ? data.key : '',
              activeDisplayName: data && data.name ? data.name : '',
            }));

            if ((!data || data.key !== 'chatV2') && this.props.showChat) {
              this.props.onShowChat(false);
            }
            break;
        }
      }, 1);
    });
  }

  _updateSelf(diff) {
    const { activeSync } = this.state;
    WidgetActions.UpdateWidget({ name: activeSync, ...diff });
  }

  updateSelf(diff) {
    WidgetActions.UpdateWidget({ name: 'chat', ...diff });
  }

  _sendNotification({ body, target_user_ids, widget }) {
    const { activeDisplayName } = this.state;

    WidgetActions.SendNotification({
      body,
      target_user_ids,
      widget,
      endpoint: 'NotifyUsers',
    });
  }

  _addLink(urls, cb = null, widgets = null) {
    WidgetActions.AddLink({ url: urls }, cb, widgets);
  }

  _removeLink(urls) {
    WidgetActions.RemoveLinkSync(urls);
  }

  _activeSync() {
    switch (this.state.activeSync) {
      case 'notes':
        return (
          <FloatingNotes
            _updateActiveSync={this._updateActiveSync}
            _updateSelf={this._updateSelf}
            addLink={this._addLink}
          />
        );

      case 'chat':
        return (
          <FloatingChat
            _updateActiveSync={this._updateActiveSync}
            _updateSelf={this._updateSelf}
            _sendNotification={this._sendNotification}
            _addLink={this._addLink}
          />
        );

      case 'links':
        return (
          <FloatingLinks
            _updateActiveSync={this._updateActiveSync}
            _updateSelf={this._updateSelf}
            addLink={this._addLink}
            removeLink={this._removeLink}
          />
        );

      case 'images':
        return <FloatingImages _updateActiveSync={this._updateActiveSync} _updateSelf={this._updateSelf} />;

      case 'chatV2':
        return (
          <NewChat
            updateSync={this._updateActiveSync}
            updateSelf={this.updateSelf}
            sendNotification={this._sendNotification}
          />
        );

      default:
        return '';
    }
  }

  addFloatingWidgetsBg = () => {
    if (
      GlobalState.main &&
      GlobalState.main.widgets &&
      (!GlobalState.main.widgets.notes ||
        !GlobalState.main.widgets.sharedbnotes ||
        !GlobalState.main.widgets.chat ||
        !GlobalState.main.widgets.links ||
        !GlobalState.main.widgets.images)
    ) {
      let widgets = [];

      if (!GlobalState.main.widgets.notes) {
        widgets.push('notes');
        // WidgetActions.HideSync('notes')
      }
      if (!GlobalState.main.widgets.sharedbnotes) {
        widgets.push('sharedbnotes');
        // WidgetActions.HideSync('sharedbnotes')
      }
      if (!GlobalState.main.widgets.chat) {
        widgets.push('chat');
      }
      if (!GlobalState.main.widgets.images) {
        widgets.push('images');
        // WidgetActions.HideSync('images')
      }
      if (!GlobalState.main.widgets.links) {
        widgets.push('links');
        // WidgetActions.HideSync('links')
      }

      Sagas.addWidget(widgets, {});
    }
  };

  doc = null;

  ObjectId = (m = Math, d = Date, h = 16, s = (s) => m.floor(s).toString(h)) =>
    s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));

  subscribeToChat() {
    console.log(this.doc ? 'true' : 'false', this.doc);
    if (this.doc) this.doc.unsubscribe();

    setTimeout(() => {
      let meetingURL = GlobalState.main.meetingName;
      let myId = (GlobalState.auth.jwt && GlobalState.auth.jwt.data._id) || null;
      const myInfo = GlobalState.main.users.find((user) => user.id === myId);

      this.doc = connection.get('chats', meetingURL);

      this.doc.subscribe((error) => {
        if (error) return console.error(error);

        if (!this.doc.type) {
          this.doc.create(
            {
              roomId: meetingURL,
              users: [myId],
              seen: { [myId]: new Date().getTime() },
              notification: { [myId]: 'all' },
              lastMessage: new Date().getTime(),
              connections: {},
            },
            (error) => {
              if (error) return console.error(error);
              console.log('updated create', this.doc.data);
            },
          );
        } else {
          const chatData = this.doc.data;
          //Check if im already on users list
          console.log('checking user if in chat', myId, chatData.users, chatData.users.indexOf(myId) === -1);
          if (chatData.users.indexOf(myId) === -1) {
            //if not add my id
            if (this.doc.type)
              this.doc.submitOp(
                [
                  { p: ['users', 0], li: myId },
                  { p: ['seen', myId], oi: new Date().getTime() },
                  { p: ['notification', myId], oi: 'all' },
                ],
                {},
                () => {
                  console.log('New user Join the chat', myId);
                  const doc = connection.get('chat-contents', this.ObjectId());
                  doc.create(
                    {
                      receiver: meetingURL,
                      parentID: meetingURL,
                      meetingURL,
                      sender: {
                        avatar: myInfo.avatar_url || '',
                        name: myInfo.name,
                        id: myId,
                      },
                      type: 'info',
                      message: `${myInfo.name} joined the conversation.`,
                      timeStamp: new Date().getTime(),
                    },
                    (err) => {
                      if (err) console.error(err);
                    },
                  );
                },
              );
          } else {
            //if yes update my seen timestamp
            console.log('User already in chat', myId);
          }

          this.getUnreadMessages(chatData, myId);
        }
      });

      this.doc.on('op', (data) => {
        this.getUnreadMessages(this.doc.data, myId);
      });
    }, 1000);
  }

  componentDidMount() {
    console.log('floating did mount');
    this.subscribeToChat();
  }

  componentWillUnmount() {
    if (this.doc) this.doc.unsubscribe((err) => console.log('unsubscribe callback', err));
  }

  getUnreadMessages = (db, myId) => {
    if (this.state.activeSync === 'chatV2') return;
    console.log('getUnread called', db, myId);

    const timeStamp = new Date().getTime();

    Object.values(db.connections || {}).map((connection, idx) => {
      if (connection.users) {
        const users = connection.users;
        const myIdx = users.indexOf(myId);
        //check myID and userID if existing to this connection
        if (myIdx > -1) {
          if (users.length > 2) {
            //connection is GC
          } else {
            //connection is DM
            const lastSeen = connection.lastSeen[myId];
            getUnseenMessage(Object.keys(db.connections)[idx], lastSeen).then((count) => {
              this.setState({ unreadMessages: this.state.unreadMessages + count });
            });
          }
        }
      }
    });

    const lastSeen = db.seen[myId];
    getUnseenMessage(db.roomId, lastSeen, true).then((count) => {
      this.setState({ unreadMessages: this.state.unreadMessages + count });
    });
  };

  render() {
    const { showFloat } = this.state;
    const widgets = [
      // {
      //     name: 'Whiteboard',
      //     icon: WhiteboardIcon
      // },
      // {
      //     name: 'Chat',
      //     key: 'chat',
      //     icon: ChatIcon
      // },
      // {
      //     name: 'Chat',
      //     key: 'chatV2',
      //     icon: ChatIcon
      // },
      {
        name: 'Notes',
        key: 'notes',
        icon: NotesIcon,
      },
      {
        name: 'Links',
        key: 'links',
        icon: Links,
      },
      // {
      //     name: 'Images',
      //     key: 'images',
      //     icon: Images
      // },
      // {
      //     name: 'Tasks',
      //     key: 'todo',
      //     icon: Todo
      // },
      // {
      //     name: 'Files',
      //     key: 'files',
      //     icon: FilesIcon
      // },
      // {
      //     name: 'PDF Presenter',
      //     key: 'pdfPresenter',
      //     icon: Presenter
      // },
      // {
      //     name: 'Transcription',
      //     key: 'transcription',
      //     icon: Transcription
      // },
    ];

    const active = this._activeSync();
    const unreadMessages = this.state.unreadMessages;

    if (this.props.chatOnly) {
      return (
        <React.Fragment>
          <Container
            id="floating-widget"
            data-tour="tour1-chat"
            mobileCollapse={this.props.mobileCollapse}
            inCall={GlobalState?.calling?.status === CallingInstanceState.Connected}
          >
            {GlobalState?.calling?.status === CallingInstanceState.Connected ? (
              <MeetingControlBtnWrapper>
                <GridWrapper
                  // data-tip
                  // data-for='floating-chat'
                  className={`button border-lightimp button topbar`}
                  onClick={() =>
                    this._updateActiveSync({
                      name: 'Chat',
                      key: 'chatV2',
                      icon: ChatIcon,
                    })
                  }
                  // onMouseOver={(e) => {
                  //     e.stopPropagation();
                  //     if (!this.state.showFloat && !!!this.state.fromClick) {
                  //         this.setState(() => ({ showFloat: true }));
                  //         this.addFloatingWidgetsBg();
                  //     }
                  // }}
                  // onMouseLeave={() => { this.setState(() => ({ fromClick: false })); }}
                >
                  <img src={ChatIcon} width={18} alt="Chat" />
                </GridWrapper>
                <div className="ctrl-label">Chat</div>
              </MeetingControlBtnWrapper>
            ) : (
              <GridWrapper
                data-tip
                data-for="floating-chat"
                className={`button border-lightimp button topbar withshadowfloating`}
                onClick={() =>
                  this._updateActiveSync({
                    name: 'Chat',
                    key: 'chatV2',
                    icon: ChatIcon,
                  })
                }
                // onMouseOver={(e) => {
                //     e.stopPropagation();
                //     if (!this.state.showFloat && !!!this.state.fromClick) {
                //         this.setState(() => ({ showFloat: true }));
                //         this.addFloatingWidgetsBg();
                //     }
                // }}
                // onMouseLeave={() => { this.setState(() => ({ fromClick: false })); }}
              >
                <img src={ChatIcon} width={18} alt="Chat" />
              </GridWrapper>
            )}

            {/* Tip Declarations */}
            {!!!isMobile && (
              <CustomTip top={-20} left={-5} class="top-right" place="left" tip={'Chat'} dataFor="floating-chat" />
            )}

            {!!unreadMessages && this.state.activeSync !== 'chatV2' && (
              <BadgeWrapper>
                <Badge floatingMenu={true}></Badge>
              </BadgeWrapper>
            )}
          </Container>
          {active ? (
            <FloatingContainer
              className={`topbar rounded inner floating-container ${this.state.activeSync === 'chatV2' ? 'chat' : ''}`}
            >
              {active}
            </FloatingContainer>
          ) : (
            ''
          )}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Container id="floating-widget" mobileCollapse={this.props.mobileCollapse}>
          <GridWrapper
            data-tip
            data-for="floating"
            className={`button ${showFloat ? 'default' : 'default'}`}
            onClick={(e) => {
              e.stopPropagation();
              this.setState((prevState) => ({ showFloat: !prevState.showFloat, fromClick: true }));
              this.addFloatingWidgetsBg();
            }}
            // onMouseOver={(e) => {
            //     e.stopPropagation();
            //     if (!this.state.showFloat && !!!this.state.fromClick) {
            //         this.setState(() => ({ showFloat: true }));
            //         this.addFloatingWidgetsBg();
            //     }
            // }}
            onMouseLeave={() => {
              this.setState(() => ({ fromClick: false }));
            }}
          >
            {showFloat ? <CloseIcon size={18} /> : <img src={GridIcon} width={18} alt="Tools" />}
          </GridWrapper>

          {/* Tip Declarations */}
          {/* {
                        !!!isMobile &&
                        <CustomTip top={-20} left={-5} class='top-right' place='left' tip={showFloat ? 'Hide Tools' : 'Show Tools'} dataFor='floating' />
                    } */}

          {/* {
                        !!unreadMessages && this.state.activeSync !== 'chat' &&
                        <BadgeWrapper>
                            <Badge floatingMenu={true}>
                            </Badge>
                        </BadgeWrapper>
                    } */}
        </Container>
        {widgets.map((widget, idx) => {
          const multiplier = (idx + 1) * 125;
          const badge = widget.key === 'chatV2' && unreadMessages;
          return (
            <React.Fragment key={idx}>
              {!!!isMobile && (
                <CustomTip
                  top={-20}
                  left={-5}
                  class="top-right"
                  place="left"
                  tip={`${widget.name}`}
                  dataFor={`${widget.name}`}
                />
              )}

              <FloatingSync
                data-tip
                className={`border-lightimp button topbar floating-sync ${showFloat ? 'shown' : ''}`}
                key={`floating${idx}${widget.name}`}
                multiplier={`-${multiplier}%`}
                data-for={widget.name}
                onClick={() => this._updateActiveSync(widget)}
                mobileCollapse={this.props.mobileCollapse}
              >
                {!!badge && this.state.activeSync !== 'chatV2' && (
                  <BadgeWrapper>
                    <Badge>{/* {badge} */}</Badge>
                  </BadgeWrapper>
                )}
                <img src={widget.icon} alt={widget.name} />
              </FloatingSync>
            </React.Fragment>
          );
        })}
        {active ? (
          <FloatingContainer
            className={`topbar rounded inner floating-container ${this.state.activeSync === 'chatV2' ? 'chat' : ''}`}
          >
            {active}
          </FloatingContainer>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

const FloatingContainer = styled.div`
  position: fixed;
  bottom: 35px;
  right: 90px;
  /* background-color: white; */
  box-shadow: ${(props) => props.theme.shadows.newheavy};
  min-width: 400px;
  max-width: 400px;
  min-height: 500px;
  max-height: 500px;
  overflow: hidden;
  z-index: 100;

  &.chat {
    min-height: 450px;
    max-height: 450px;
  }

  @media (max-height: 740px) {
    min-height: 330px;
    max-height: 330px;
  }

  @media (max-width: 970px) {
    bottom: 180px;
    right: 70px;
  }

  @media (max-width: 480px) {
    min-width: 330px;
    max-width: 330px;
    min-height: 330px;
    max-height: 330px;
  }
`;

const Container = styled.div`
  position: fixed;
  bottom: ${({ inCall }) => (inCall ? '40px' : '35px')};
  right: 40px;
  z-index: 100;

  @media (max-width: 970px) {
    bottom: 150px;
    right: 20px;
  }

  @media (max-width: 970px), (max-width: 900px) and (orientation: landscape) {
    transition: all 0.3s ease;
    ${({ mobileCollapse }) => (mobileCollapse ? 'bottom: 50px' : 'bottom: 180px')};
    right: 20px;
  }
`;

const GridWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 1px solid;

  &.default {
    transition: transform 0.4s ease-in-out;
    transform: rotateZ(90deg);
  }

  &.withshadowfloating {
    box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
  }
`;

const FloatingSync = styled.div`
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${(props) => props.theme.shadows.newheavy};
  position: fixed;
  z-index: 102;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
  transition: visibility 0.3s ease-in;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  img {
    width: 18px;
    height: 18px;
  }

  &.shown {
    transform: ${(props) => `translateY(${props.multiplier})`};
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }

  @media (max-width: 970px) {
    bottom: 150px;
    right: 20px;
  }

  @media (max-width: 970px), (max-width: 900px) and (orientation: landscape) {
    transition: all 0.3s ease;
    ${({ mobileCollapse }) => (mobileCollapse ? 'bottom: 50px' : 'bottom: 180px')};
    right: 20px;
  }
`;

const Badge = styled.div`
  position: relative;
  top: -16px;
  right: -15px;
  height: 18px;
  width: 18px;
  border-radius: 10px;
  line-height: 20px;
  background-color: #ff5555;
  color: #fff;
  text-align: center;
  display: block;
  font-size: 10px;

  ${(props) => props.floatingMenu && 'top: -42px; right: -30px;'}
`;

const BadgeWrapper = styled.div`
  position: absolute;
`;

export default FloatingWidgets;
