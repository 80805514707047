// import { restructureCompiler } from './eventsHandler'

// Get events if access token is found without sign in popup
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || 'AIzaSyD1wUtxAFrrztvmrIYruczD8TWnDyV6oyE' // (Mark's Testing)
export const fetchWithAccessToken = (params, callback) => {
  // added timezone specific and fetch with the specific dates min and max dates
  let addl_url_query = ''
  Object.keys(params.query).forEach(key => {
    addl_url_query += `&${key}=${params.query[key]}`
  })
  try {
    fetch(
    `https://www.googleapis.com/calendar/v3/calendars/primary/events?key=${API_KEY}&orderBy=startTime&singleEvents=true${addl_url_query}`,
      {
        headers: {
          Authorization: `Bearer ${params.access_token}`,
        },
      }
    )
      .then((res) => {
        // Check if unauthorized status code is return open sign in popup
        if (res.status !== 401) {
          return res.json();
        }

        // console.log('googleCalendar', res)
        return { status: res.status, message: res.statusText }
      })
      .then((data) => {
        if (data.status === 401) {
          return callback(data)
        }

        if (data.items) {
          // maybe restructure the items from google api?
          // let events_structure = [
          //   {}
          // ]

          // restructureCompiler()
          callback(data.items)
        }
      })
      .catch(_err => {
        console.log('catch error', _err)
        callback({ error: true, message: 'api error', details: _err })
      });
  } catch(_err) {
    console.log('try catch error', _err)
    callback({ error: true, message: 'api error', details: _err })
  }

}


export const isValidUrl = (url) => {
  try {
    new URL(url);
  } catch (e) {
    // console.error(e);
    return false;
  }
  return true;
};