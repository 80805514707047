import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'

const InputGroupItems = forwardRef((props, ref) => {

    const { restriction, setError, Icon, disabled, placeholder, setItems, items, name, maxHeight } = props

    const [input, setInput] = useState('')
    const [isKeyReleased, setIsKeyReleased] = useState(false)

    const contRef = useRef()

    const onChange = (e) => {
        const { value } = e.target
        setInput(value)
    }

    const onKeyDown = (e) => {
        const { key } = e
        const trimmedInput = input.trim()
        setError(null)

        if (!!!trimmedInput && key !== "Backspace") {
            setInput('')
            return
        }

        if (key === 'Enter' || key === ',' || (key === ' ' || key === 'Spacebar') && trimmedInput.length) {
            e.preventDefault()

            if (items.includes(trimmedInput)) {
                setInput('')
                return
            }

            if (restriction(trimmedInput)) {
                setItems([...items, trimmedInput])
            } else {
                setError('Invalid email format.')
            }

            setInput('')
        }

        if (key === "Backspace" && !input.length && items.length && isKeyReleased) {
            e.preventDefault()
            const itemsCopy = [...items]
            const poppedItem = itemsCopy.pop()

            setItems(itemsCopy)
            setInput(poppedItem)
        }

        setIsKeyReleased(false)
    }

    const onKeyUp = () => {
        setIsKeyReleased(true)
    }

    const deleteItem = (index) => {
        setError(null)
        const itms = items.filter((item, i) => i !== index)
        setItems(itms)
    }

    const scrollToBottom = () => {
        const scrollElement = contRef.current
        const scrollOptions = {
            top: scrollElement.scrollHeight,
            behavior: 'auto'
        }
        scrollElement.scroll(scrollOptions)
    }

    useImperativeHandle(ref, () => ({
        getInput: () => input.trim(),
        setInput: (input) => setInput(input),
        scrollToBottom: () => scrollToBottom()
    }))

    return (
        <Container className="border-light" maxHeight={maxHeight} ref={contRef}>
            {items.map((item, index) => (
                <Item key={index} className="color-textimp">
                    <Iconwrapper><Icon /></Iconwrapper>
                    {item}
                    <button onClick={() => deleteItem(index)} className="color-textimp">x</button>
                </Item>
            ))}
            <Input
                className='color-textimp'
                value={input}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                onChange={onChange}
                onKeyUp={onKeyUp}
                onFocus={() => setError(null)}
                disabled={disabled}
                autoComplete="off"
                name={name}
            />
        </Container>
    )
})

const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    padding-top: 5px;
    color: black;
    /* margin-top: 30px; */
    border: 1px solid;
    flex-wrap: wrap;
    border-radius: 5px;
    margin-right: 2px;
    padding: 5px;
    max-height: ${({maxHeight}) => maxHeight};
    overflow-y: auto;
    ::-webkit-scrollbar-corner {background-color: transparent;}
`

const Input = styled.input`
    width: 100%;
    min-width: 50%;
    border: none;
    border-radius: 5px;
    padding: 0 !important;
    margin: 5px 0 5px 5px;
    background-color: unset;
    font-size: 12px !important;


    &::-webkit-input-placeholder {
        opacity: 1 !important;
        font-size: 12px;
    }

    &::placeholder {
        opacity: 1 !important;
        font-size: 12px;
    }
`

const Item = styled.div`
    display: flex;
    align-items: center;
    margin: 2px 0;
    margin-right: 10px;
    padding: 0 10px;
    padding-right: 5px;
    border: 1px solid orange;
    border-radius: 20px;
    white-space: nowrap;
    border-color: #E9ECEF;
    padding-left: 2px;
    font-size: 12px;

    button {
        display: flex;
        padding: 6px;
        border: none;
        background-color: unset;
        cursor: pointer;
    }

`

const Iconwrapper = styled.div`
    height: 25px;
    width: 25px;
    padding: 0px;
    background-color: #d0d0d0;
    border-radius: 132px;
    margin-right: 4px;
    svg {
        height: 20px;
        width: 20px;
        fill: #fff;
    }
`

export default InputGroupItems