import React, { Component } from 'react';
import styled from 'styled-components';
import WidgetPickerItem from '/components/WidgetPickerItem';
import { FiEye as EyeIcon } from 'react-icons/fi'
import { GlobalState } from 'reflux';

import Sagas from '/helpers/Sagas';
import Modal from '../../../components/Modalv2';
import { WidgetActions } from '/stores/MainStore';
import { CallingInstanceState } from '../../../calling/types';
import LoopApi from '../../../helpers/LoopApi';

export default class AddWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unattachedWidgets: null,
      locallyHidden: {},
      selectedWidgets: [],
      syncs: '',
    };
  }

  async componentDidMount() {
    console.log('AddWidget componentDidMount');
    const limits = await LoopApi(null, 'StripeGetRoomOwnerLimits');
    console.log({ limits });
    this.setState({ syncs: limits?.available_syncs || '' });
  }

  componentDidUpdate(prevProps, prevState) {
    const enabledWidgets = this.state.syncs; //|| 'asana,clickup,dropbox,gists,github,googledrive,hubspot,scheduler,slack,tictactoe,twitch,youtube,whiteboard'
    const enabledTools = process.env.REACT_APP_TOOLS || 'pdfpresenter,transcription,notes,files,links';

    const newwidgets = this.state.unattachedWidgets.filter((el) => {
      return (
        enabledWidgets
          .split(',')
          .map((item) => {
            return item.trim();
          })
          .indexOf(el.key) >= 0
      );
    });

    const newtools = this.state.unattachedWidgets.filter((el) => {
      return (
        enabledTools
          .split(',')
          .map((item) => {
            return item.trim();
          })
          .indexOf(el.key) >= 0
      );
    });
  }

  UNSAFE_componentWillMount() {
    // LOCAL MINIMIZED WIDGETS
    const minWidgets = localStorage.getItem('minimizedWidgets');
    let parsedMinWidgets = {};
    let curMinWWidgets = [];
    if (minWidgets) {
      parsedMinWidgets = JSON.parse(minWidgets);
      curMinWWidgets =
        parsedMinWidgets && parsedMinWidgets[GlobalState.main.meetingName]
          ? parsedMinWidgets[GlobalState.main.meetingName]
          : [];
    }

    const minimizedWidgets =
      GlobalState.main &&
      GlobalState.main.db_meeting &&
      GlobalState.main.db_meeting.settings &&
      GlobalState.main.db_meeting.settings.minimizedWidgets &&
      GlobalState.main.db_meeting.settings.minimizedWidgets.length
        ? GlobalState.main.db_meeting.settings.minimizedWidgets
        : [];

    let unattachedWidgets = Object.keys(this.props.AllWidgets).filter((key) => {
      if (this.props.AllWidgets[key].widgetConfig.hidden) {
        return false;
      }
      if (GlobalState.main.db_meeting.settings.bot_mode && this.props.AllWidgets[key].widgetConfig.botModeUnavailable) {
        return false;
      }

      return true;
    });

    const isInCall = GlobalState.calling.status === CallingInstanceState.Connected;
    if (!isInCall || (isInCall && !GlobalState.calling.meetingLocked)) {
      unattachedWidgets = unattachedWidgets.filter(
        (key) => !this.props.meeting.widgets[key] || curMinWWidgets.indexOf(key) !== -1,
      );
    } else {
      unattachedWidgets = unattachedWidgets.filter(
        (key) => !this.props.meeting.widgets[key] || !!minimizedWidgets.includes(key),
      );
    }

    unattachedWidgets = unattachedWidgets.map((w) => ({
      ...this.props.AllWidgets[w].widgetConfig,
      selected: false,
    }));
    this.setState({ unattachedWidgets, locallyHidden: parsedMinWidgets });
  }

  unhideLocalWidget = (widget_name) => {
    // const tools = process.env.REACT_APP_TOOLS || 'images,pdfpresenter,todo,transcription,notes,files,links'
    // const toolsArray = tools.split(",").map((item) => { return item.trim() }).filter((item) => !!item)
    // toolsArray.map((tool) => WidgetActions.HideSync(tool))

    let parsedMinWidgets = this.state.locallyHidden;
    let curMinWidgets = this.state.locallyHidden && this.state.locallyHidden[GlobalState.main.meetingName];
    let index = (curMinWidgets || []).indexOf(widget_name);

    const isInCall = GlobalState.calling.status === CallingInstanceState.Connected;
    if (!isInCall || (isInCall && !GlobalState.calling.meetingLocked)) {
      if (index !== -1) {
        (curMinWidgets || []).splice(index, 1);
      }

      const unattachedWidgets = Object.keys(this.props.AllWidgets)
        .filter((key) => {
          if (this.props.AllWidgets[key].widgetConfig.hidden) {
            return false;
          }
          if (
            GlobalState.main.db_meeting.settings.bot_mode &&
            this.props.AllWidgets[key].widgetConfig.botModeUnavailable
          ) {
            return false;
          }

          return true;
        })
        .filter((key) => !this.props.meeting.widgets[key] || curMinWidgets.indexOf(key) !== -1)
        .map((w) => ({
          ...this.props.AllWidgets[w].widgetConfig,
          selected: false,
        }));

      WidgetActions.HideSync(widget_name);
      parsedMinWidgets = { ...parsedMinWidgets, [GlobalState.main.meetingName]: curMinWidgets };
      localStorage.setItem('minimizedWidgets', JSON.stringify(parsedMinWidgets));
      this.setState({ locallyHidden: parsedMinWidgets, unattachedWidgets });
    } else {
      const minimizedWidgets =
        GlobalState.main &&
        GlobalState.main.db_meeting &&
        GlobalState.main.db_meeting.settings &&
        GlobalState.main.db_meeting.settings.minimizedWidgets &&
        GlobalState.main.db_meeting.settings.minimizedWidgets.length
          ? GlobalState.main.db_meeting.settings.minimizedWidgets
          : [];

      const ind = minimizedWidgets.indexOf(widget_name);
      if (ind !== -1) {
        minimizedWidgets.splice(ind, 1);
      }
      Sagas.changeSetting('minimizedWidgets', minimizedWidgets);
    }
  };

  toggleWidget(widget) {
    const unattachedWidgets = this.state.unattachedWidgets;
    const selectedWidgets = this.state.selectedWidgets;

    if (!widget.selected) selectedWidgets.push(widget.key);
    else selectedWidgets.find((o, i) => selectedWidgets.splice(i, 1));

    unattachedWidgets.find((o, i) => {
      if (o.key === widget.key) {
        if (!widget.selected) unattachedWidgets[i].selected = true;
        else unattachedWidgets[i].selected = false;
        return true;
      }
      return false;
    });
    this.setState({ selectedWidgets, unattachedWidgets });
  }

  addWidgets() {
    // const tools = process.env.REACT_APP_TOOLS || 'images,pdfpresenter,todo,transcription,notes,files,links'
    // const toolsArray = tools.split(",").map((item) => { return item.trim() }).filter((item) => !!item)
    // toolsArray.map((tool) => WidgetActions.HideSync(tool))

    let new_widgets = [];
    let extra_data = {};

    for (let key of this.state.selectedWidgets) {
      if (this.props.AllWidgets[key].widgetConfig.allowsDuplicates) {
        new_widgets.push(`${Date.now()}${key}`);
        extra_data[key] = { _component: key };
      } else {
        new_widgets.push(key);
      }
    }

    const isInCall = GlobalState.calling.status === CallingInstanceState.Connected;
    if (!isInCall) {
      // || (isInCall && !GlobalState.calling.meetingLocked)
      // LOCAL STATE
      const localCurrentWidget = {
        ...GlobalState.main.localCurrentWidget,
        [GlobalState.main.meetingName]: this.state.selectedWidgets[0],
      };
      WidgetActions.SetLocalCurrentWidget({ localCurrentWidget });
      localStorage.setItem('currentWidget', JSON.stringify(localCurrentWidget));
    }

    Sagas.addWidget(new_widgets, extra_data);
    this.props.closeModal();
  }

  render() {
    const widgets = this.state.syncs; //|| 'asana,clickup,dropbox,files,gists,github,googledrive,hubspot,images,links,notes,pdfpresenter,scheduler,slack,tictactoe,todo,transcription,twitch,youtube,whiteboard'
    // const enabledWidgets = process.env.REACT_APP_ENABLED_WIDGETS || 'asana,clickup,dropbox,gists,github,googledrive,hubspot,scheduler,slack,tictactoe,twitch,youtube,whiteboard'
    const enabledTools =
      //   process.env.REACT_APP_TOOLS || 'images,pdfpresenter,todo,transcription,sharedbnotes,files,links';
      process.env.REACT_APP_TOOLS || 'pdfpresenter,transcription,sharedbnotes,files,links';

    const newwidgets = this.state.unattachedWidgets.filter((el) => {
      return (
        widgets
          .split(',')
          .filter((w) => !enabledTools.split(',').includes(w))
          .map((item) => {
            return item.trim();
          })
          .indexOf(el.key) >= 0
      );
    });

    const newtools = this.state.unattachedWidgets.filter((el) => {
      return (
        enabledTools
          .split(',')
          .map((item) => {
            return item.trim();
          })
          .indexOf(el.key) >= 0
      );
    });

    // LOCAL MINIMIZED WIDGETS
    let parsedMinWidgets =
      this.state.locallyHidden && this.state.locallyHidden[GlobalState.main.meetingName]
        ? this.state.locallyHidden[GlobalState.main.meetingName]
        : [];

    // SYNCHRONIZE
    const minimizedWidgets =
      GlobalState.main &&
      GlobalState.main.db_meeting &&
      GlobalState.main.db_meeting.settings &&
      GlobalState.main.db_meeting.settings.minimizedWidgets &&
      GlobalState.main.db_meeting.settings.minimizedWidgets.length
        ? GlobalState.main.db_meeting.settings.minimizedWidgets
        : [];
    const isInCall = GlobalState.calling.status === CallingInstanceState.Connected;
    if (isInCall && GlobalState.calling.meetingLocked) {
      debugger;
      parsedMinWidgets = minimizedWidgets;
    }

    const syncs = newwidgets.map((w) => (
      <WidgetPickerItem
        widget={w}
        key={w.key}
        toggleWidget={() => this.toggleWidget(w)}
        locallyHidden={parsedMinWidgets.indexOf(w.key) !== -1}
        unhideLocalWidget={this.unhideLocalWidget}
      />
    ));

    const tools = newtools.map((w) => (
      <WidgetPickerItem
        widget={w}
        key={w.key}
        toggleWidget={() => this.toggleWidget(w)}
        locallyHidden={parsedMinWidgets.indexOf(w.key) !== -1}
        unhideLocalWidget={this.unhideLocalWidget}
      />
    ));

    return (
      <Modal closeModal={this.props.closeModal} verticalFlex>
        <Header>Tools & Integrations</Header>
        <Subheader>Choose your tools or integrations to collaborate</Subheader>
        {tools && tools.length > 0 && (
          <React.Fragment>
            <Title>Tools</Title>
            <WidgetContainer style={{ marginBottom: syncs && syncs.length > 0 ? '10px' : '62px' }}>
              {tools}
            </WidgetContainer>
          </React.Fragment>
        )}
        {syncs && syncs.length > 0 && (
          <React.Fragment>
            <Title>Integrations</Title>
            <WidgetContainer>{syncs}</WidgetContainer>
          </React.Fragment>
        )}
        {(syncs && syncs.length === 0 && tools && tools.length === 0) && (
          <React.Fragment>
            <Empty>All tools has been added to tools sidebar, if you want to hide a tool please click the <span style={{verticalAlign: 'sub'}}><EyeIcon /></span> to hide a tool</Empty>
          </React.Fragment>
        )}
        <ButtonContainer
          className="button primary"
          disabled={this.state.selectedWidgets.length === 0}
          onClick={() => this.addWidgets()}
        >
          {this.state.selectedWidgets.length > 0 ? `Add Selected` : `Add`}
        </ButtonContainer>
      </Modal>
    );
  }
}

const Header =styled.div`
  padding: 25px 15px 15px;
  font-size: 18px;
  font-weight: bold;
`

const Subheader = styled.div`
  opacity: 0.5;
  font-weight: 500;
`;

const Empty = styled.div`
  padding: 30px 30px 50px;
  width: 500px;
  line-height: 20px;
`

const Title = styled.div`
  text-align: left;
  padding: 20px 20px 10px;
  font-weight: 500;
  font-size: 14px;
`;

const ButtonContainer = styled.div`
  position: sticky;
  left: 10px;
  right: 10px;
  bottom: 10px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.3s ease-out;
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  margin: 0 10px;
  &:hover {
    box-shadow: ${(props) => props.theme.shadows.light};
  }
  ${(props) =>
    props.disabled &&
    `
		opacity: 0.6;
		pointer-events: none;
	`};
`;
const WidgetContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100px;
  min-height: 110px;
  margin: 10px;
  margin-bottom: 62px;
  overflow-x: hidden;

  @media (min-width: 410px) {
    min-width: 322px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 515px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;
