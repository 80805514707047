import React, { Component } from 'react';
import styled from 'styled-components';

import Modal from '/components/Modalv2';
import { FiInfo as InfoIcon } from '@react-icons/all-files/fi/FiInfo';
const { GlobalState } = require('reflux')

export default class MaximumAllowedParticipants extends Component {
    constructor(props) {
        super(props);

        this.Remove = this.Remove.bind(this);
    }

    Remove() {
        this.props.closeModal();
    }

    render() {
        return (
            <Modal closeModal={this.props.closeModal} dismissible={false}>
                <Container>
                    <Icon className='container'>
                        <InfoIcon />
                    </Icon>
                    <Title>Meeting Participants</Title>
                    <Info>
                    {
                        GlobalState?.main?.db_meeting?.organization ? 
                        'You have reached your meeting participants limit. Please contact your Grapl Admin to upgrade your plan. You can still proceed with this meeting, but additional rates may apply.' :
                        'Maximum meeting participants limit reached. Please go to your User Profile to upgrade your plan. You can still proceed with this meeting, but additional rates may apply.' 
                    }
                        Maximum meeting participants limit reached. Please go to your user profile to upgrade your plan.
                    </Info>
                    <Options>
                        <Button onClick={this.Remove} id='dismiss' className='button primary'>
                            Ok
                        </Button>
                    </Options>
                </Container>
            </Modal>
        );
    }
}

const Icon = styled.div`
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: inherit;
`;

const Info = styled.div`
    font-weight: 400;
    color: inherit;
    line-height: 20px;
    margin: 10px 0 15px;
    opacity: 0.5;
`;

const Container = styled.div`
    position: relative;
    text-align: center;
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 420px;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
    margin: 15px 0;
    &::first-letter {
        text-transform: uppercase;
    }
`;

const Options = styled.div`
    display: flex;
    width: 100%;
`;

const Button = styled.div`
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 14px;
    margin: 4px;
    font-weight: 400;
`;
