import React, { Fragment } from 'react'
import styled from 'styled-components'
import Reflux from 'reflux'
import Recaptcha from '/components/Recaptcha'
import MainHeader from '../Header'

import { AuthStore } from '/stores/AuthStore'
import LoopApi from '/helpers/LoopApi'
import BackButton from '/components/BackButton'
import ClearInput from '/components/ClearInput'
import Color from 'color'
import bg from '../../assets/backgrounds/LandingBG.png'
import bg1 from '../../assets/backgrounds/Landing.png'
import image1 from '../../assets/backgrounds/background.png'
import ModalManager from '../Meeting2.0/ModalManager'
import { ModalActions } from '../../stores/ModalStore'
import { LinkButton } from '../Login/styled'

const email_regex = /.+@.+\..+/
export default class ChooseLogin extends Reflux.Component {
	constructor(props) {
		super(props)
		this.store = AuthStore
		this.storeKeys = ['intended_join']
		this.state = {
			loading: false,
			intended_join: '',
			username: '',
			email: '',
			captcha_response: null,
			exists: false,
			requires_captcha: true,
            hasMaxParticipants: null,
			meeting_name: ''
		}
		this.recaptchaInstance = null
	}

	UNSAFE_componentWillMount() {
		if (!this.props.match.params.meetingName) {
			this.props.history.push('/login')
		}
		this.getPublicData()
		// Reflux.Component.prototype.UNSAFE_componentWillMount.call(this)
	}

	async getPublicData() {
		try {
			const public_data = await LoopApi(null, 'CheckIfMeetingIsPublic', {}, [
				['name', this.props.match.params.meetingName],
			])
            
			this.setState({
				exists: !!public_data.exists,
                hasMaxParticipants: public_data.hasMaxParticipants,
				meeting_name: public_data.meeting_name || ''
				// requires_captcha: public_data.requires_captcha,
			})
            if(public_data.hasMaxParticipants) this.setState({ errorMessage: 'This room has max participants, upgrade your plan to continue.' })
		} catch (e) {
            this.setState({ errorMessage: 'This room has no subscription' });
            window.location.href = '/login'
			console.error(e)
		}
	}

	handleChange(field, event) {
		const change = {}
		change[field] = event.target.value
		change['errorMessage'] = ''
		this.setState(change)
	}

	_isInputGood = () => {
        return (
            email_regex.test(this.state.email)
        )
    }

	async handleSubmit(event) {
		event.preventDefault()

		if(!this.state.email) {
			return this.setState({ errorMessage: 'Email address is required' })
		}

		if(!this.state.username?.trim()) {
			return this.setState({ errorMessage: 'Username is required' })
		}
		if ((this.state.requires_captcha && !this.state.captcha_response) || this.state.hasMaxParticipants) {
			return
		}

		if (!this._isInputGood()) {
            return this.setState({ errorMessage: 'Please provide a valid email address' })
        }

		this.setState({ loading: true, errorMessage: null })

		try {
			const resp = await LoopApi(null, 'SignupAsGuest', {
				username: this.state.username,
				meetingName: this.props.match.params.meetingName,
				captcha_response: this.state.captcha_response,
				email: this.state.email
			})
			if (resp.error) {
				this.recaptchaInstance && this.recaptchaInstance.reset()
				this.setState({
					loading: false,
					errorMessage: resp.msg || (typeof resp === 'string' ? resp : ''),
				})
			} else {
				localStorage.setItem('token', resp.token.token)
				this.props.history.push(`/${this.props.match.params.meetingName}`)
			}
		} catch (err) {
			if(
				typeof window !== 'undefined' &&
				typeof window.grecaptcha !== 'undefined' &&
				typeof window.grecaptcha.reset === 'function'
			) {
				window.grecaptcha.reset()
			}
			if(this.recaptchaInstance && this.recaptchaInstance.reset) this.recaptchaInstance.reset()
			console.error(err)
			this.setState({
				loading: false,
				errorMessage: typeof err === 'string' ? err : '',
				captcha_response: null
			})

			if(err.existing) {
				ModalActions.SetModal('EmailRegistered')
			}
		}
	}

	
	render() {
		if(this.state.exists) {
			return (
				<ContainerGuest>
					<MainHeader login white customLogin={() =>
						this.props.history.push(
							`/login/${this.props.match.params.meetingName}`
						)}
					/>
					<Left>
						<img src={image1} alt="Grapl" />
					</Left>

					<Right>
						<GuestTitle>Join as Guest</GuestTitle>
						<GuestRoomName>{this.state.meeting_name}</GuestRoomName>
						<GuestRoomLink>{this.props.match.params.meetingName}</GuestRoomLink>
						<GuestCreateForm onSubmit={this.handleSubmit.bind(this)}>
							<FormField
								data-lpignore="true"
								type="text"
								placeholder="Display Name"
								value={this.state.username}
								maxLength={50}
								onChange={this.handleChange.bind(this, 'username')}
							/>
							<FormField
								data-lpignore="true"
								type="email"
								placeholder="Email Address"
								value={this.state.email}
								maxLength={50}
								// required
								onChange={this.handleChange.bind(this, 'email')}
							/>
							<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
								<Recaptcha
									verifyCallback={ev => {
										//console.log('ev', ev)
										this.setState({ captcha_response: ev })
									}}
									ref={e => (this.recaptchaInstance = e)}
									sitekey={
										process.env.RECAPTCHA_KEY ||
										'6Lf6hU8UAAAAAGNxud_6MqSz3W5kq9c_0hIyHSuE'
									}
								/>
							</div>

							
							<SubmitButton
								disabled={
									this.state.loading ||
									this.state.username === '' || 
									this.state.email === '' || 
									(this.state.requires_captcha &&
										this.state.captcha_response === null)
								}
								value={'Join'}
								type="submit"
							/>
							{this.state.errorMessage && (
								<ErrorMsg>{this.state.errorMessage}</ErrorMsg>
							)}

							<CustomLinkButton onClick={() => {
								this.props.history.push(
								`/login/${this.props.match.params.meetingName}`
								)}
							}>Login my Account</CustomLinkButton>
						</GuestCreateForm>
					</Right>



					<ModalManager history={this.props.history} />
				</ContainerGuest>
			)
		}

		return (
			<Container>
				<MainHeader login customLogin={() =>
					this.props.history.push(
						`/login/${this.props.match.params.meetingName}`
					)}
				/>
				{/* <BackButton onClick={() => this.props.history.goBack()} /> */}
				<CreateForm onSubmit={this.handleSubmit.bind(this)}>
					<Title>
						{this.state.exists ? 'JOIN ROOM' : 'Create Room'}
					</Title>
					<RoomName>{this.state.meeting_name}</RoomName>
					<RoomURL>{this.props.match.params.meetingName}</RoomURL>

					{!this.state.exists &&
						<Subtitle className="subheader">
							<Fragment>
								Create a name that describes the room <br /> activity or content
							</Fragment>
						</Subtitle>
					}
					{/* <LoginLink
						onClick={
						}
					>
						Already have an account?
					</LoginLink> */}
					<FormField
						data-lpignore="true"
						type="text"
						placeholder="Display Name"
						value={this.state.username}
						maxLength={50}
						onChange={this.handleChange.bind(this, 'username')}
					/>
					<FormField
						data-lpignore="true"
						type="email"
						placeholder="Email Address"
						value={this.state.email}
						maxLength={50}
						// required
						onChange={this.handleChange.bind(this, 'email')}
					/>
					{this.state.requires_captcha && (
						<Recaptcha
							verifyCallback={ev => {
								//console.log('ev', ev)
								this.setState({ captcha_response: ev })
							}}
							ref={e => (this.recaptchaInstance = e)}
							sitekey={
								process.env.RECAPTCHA_KEY ||
								'6Lf6hU8UAAAAAGNxud_6MqSz3W5kq9c_0hIyHSuE'
							}
						/>
					)}
					<SubmitButton
						disabled={
							this.state.loading ||
							this.state.username === '' || 
							this.state.email === '' || 
							(this.state.requires_captcha &&
								this.state.captcha_response === null)
						}
						value={this.state.exists ? 'Join as Guest' : 'Create Guest Meeting'}
						type="submit"
					/>
					{this.state.errorMessage && (
						<ErrorMsg>{this.state.errorMessage}</ErrorMsg>
					)}

					<CustomLinkButton onClick={() => {
						this.props.history.push(
						`/login/${this.props.match.params.meetingName}`
						)}
					}>Login my Account</CustomLinkButton>
				</CreateForm>

				<ModalManager history={this.props.history} />
			</Container>
		)
	}
}

const GuestTitle = styled.div`
	font-size: 24px;
	font-weight: 600;
	margin: 4px 0 15px;
	color: #00205b;
`

const GuestRoomName = styled.div`
	font-weight: 600;
	text-align: left;
	font-size: 16px;
	margin-bottom: 15px;
`

const GuestRoomLink = styled.div`
	margin-bottom: 24px;
	/* margin-top: 4px; */
	font-size: 12px;
	color: #00205b;
	font-weight: 400;
`

const GuestCreateForm = styled.form`
	/* background-color: white; */
	/* padding: 30px; */
	/* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05); */
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	min-width: 360px;
	min-height: 240px;
	position: relative;
	justify-content: space-around;
`

const CustomLinkButton = styled(LinkButton)`
	font-size: 14px;
`;

const RoomName = styled.div`
	font-size: 24px;
	font-weight: 600;
	margin: 4px 0;
	color: #00205b;
`

const RoomURL = styled.div`
	margin-bottom: 24px;
    margin-top: 4px;
    font-size: 12px;
    color: #00205b;
    font-weight: 400;
`

const FormField = styled.input`
      margin-bottom: 10px;
      padding: 10px 15px;
      font-size: 14px;
      outline: none;
      border-radius: 999px;
      margin-bottom: 10px;
      border: 1px solid #E9ECEF;
      color: #363B45;

      &::placeholder {
            color: ${() => Color('#5F5F77').alpha(0.8).toString()};
      }

      &:focus {
            /* outline: 1px solid blue; */
      }

      &.last {
            margin-bottom: 20px;
      }
`

export const ContainerGuest = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    font-size: 14px;
    font-weight: 400;
    background: url(${bg1}) no-repeat center 50% fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
`

const Left = styled.div`
  flex: 1;
  text-align: center;

  img { 
    width: 80%;
  }
`

const Right = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	background: url(${bg}) no-repeat center 70% fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
`

const CreateForm = styled.form`
	background-color: white;
	padding: 30px;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	min-width: 360px;
	min-height: 240px;
	position: relative;
	justify-content: space-around;
`

const SubmitButton = styled.input`
	margin-top: 10px;
	margin-bottom: 10px;
	background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%);
	border-radius: 999px;
	border: none;
	font-size: 14px;
	color: white;
	padding: 10px;
	cursor: pointer;
	font-weight: 400;

	&:disabled {
		opacity: 0.5;
		cursor: initial;
		pointer-events: none;
	}

	&:hover {
		opacity: 0.9;
	}
`

const ErrorMsg = styled.div`
	color: rgb(200, 50, 50);
	font-size: 12px;
	text-align: center;
`

const Title = styled.div`
	font-weight: 500;
	text-align: left;
	font-size: 15px;
	margin-bottom: 4px;
`

const LoginLink = styled.div`
	cursor: pointer;
	text-decoration: underline;
	color: white;
	position: absolute;
	bottom: -42px;
	text-align: center;
	left: 0;
	right: 0;
	font-size: 14px;
`

const Subtitle = styled.div`
	font-size: 14px;
	margin-bottom: 25px;
	text-align: center;
`