import React, { memo } from 'react';
import styled from 'styled-components';
import Modal from '/components/Modalv2';
import LoopApi from '../../../helpers/LoopApi';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';

const { GlobalState } = require('reflux');


const RecentRooms = memo((props) => {
    const [rooms, setRooms] = React.useState([])

    React.useEffect(() => {
        fetchRoomsInfo()
    }, [])

    const fetchRoomsInfo = async () => {
        const localRooms = JSON.parse(localStorage.getItem('recentRooms')).slice(0, 10)
        const recentMeetings = await LoopApi(null, 'GetRecentRooms', { meetings: localRooms.map(r => r.room) }, [])

        const recents = recentMeetings.meetings.map(rm => {
            const localroom = localRooms.find(lr => lr.room === rm.name)
            return {
                ...rm,
                ...localroom
            }
        }).sort((a, b) => {
            return moment(b.lastVisit).diff(moment(a.lastVisit))
        })

        setRooms(recents)
    }

    return (
        <Modal closeModal={props.closeModal} dismissible={true}>
            <Container>
                <Title>Recent Rooms</Title>
                
                {
                    (rooms || []).length ? 
                    <div style={{ height: '500px', width: '520px' }}>
                    <Scrollbars
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        style={{height: '100%'}}
                    >
                    <div style={{ padding: '0px 10px' }}>
                    {
                    rooms.map((room => {
                        return (
                            <RoomRows
                                key={room._id}
                                onClick={() => window.location.href = `/${room.room}`}
                            >
                                <RoomAvatar>
                                    <img src={room.avatar_url || `https://ui-avatars.com/api/?name=${room.room_alias || room.room || 'Room'}&background=${(GlobalState.theming.color_scheme || 'Light') === 'Light' ? 'F2F4F6' : '21212B&color=FEFEFE'}&format=svg`}
                                    alt={room.room_alias || room.room || '-'} />
                                </RoomAvatar>
                                <RoomDetails>
                                    <div className="room-name">
                                        {room.room}
                                    </div>
                                    <div className="room-name-alias color-primary">
                                        {room.room_alias}
                                    </div>
                                    <div className="room-creator">
                                        {room.creator}
                                    </div>
                                </RoomDetails>
                                <LastAccessed>
                                    Access {moment(room.lastVisit).fromNow()}
                                </LastAccessed>
                            </RoomRows>
                        )
                    })) 
                    }</div>
                    </Scrollbars></div>: 'No Recent Rooms'
                }
                <Close onClick={() => props.closeModal()}>Close</Close>
            </Container>
        </Modal>
    );
});


export default RecentRooms;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
`

const Title = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
	font-weight: 700;
	font-size: ${props => props.theme.textLG};
    margin: 20px 0;
`

const RoomRows = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
`

const RoomAvatar = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 5px;
    min-width: 40px;
    margin-right: 20px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50px;
        object-fit: cover;
    }
`

const RoomDetails = styled.div`
    display: flex;
    flex-direction: column;
    margin: 15px 20px 15px 0;
    align-items: flex-start;
    min-width: 250px;

    .room-name {
        font-size: 17px;
        padding-bottom: 5px;
    }

    .room-creator {
        font-size: 11px;
    }

    .room-name-alias {
        font-size: 11px;
        padding-bottom: 5px;
    }
`

const LastAccessed = styled.div`
    margin-left: auto
`

const Close = styled.div`
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
    margin-top: 17px;
    font-weight: 500;
`
