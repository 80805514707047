import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import LoopApi from '/helpers/LoopApi'
import { MainStore } from '/stores/MainStore'
import { fill_string_dates } from '/helpers/PresentationHelpers'
import { AiOutlineCheck, AiOutlineLink } from 'react-icons/ai'
import useToastify from '../../../helpers/useToastify'
import { Zoom } from 'react-toastify'
import { ThemingStore } from '../../../stores/ThemingStore'
import CustomTip from '../../../helpers/CustomTip'
import { AuthStore } from '../../../stores/AuthStore'
import SuccessErrorToast from '../../../components/Notifications/SuccessErrorToast'
import { MainActions } from '../../../stores/MainStore'

export default class MeetingTitle extends Reflux.PureComponent {
	constructor(props) {
		super(props)
		this.stores = [MainStore, ThemingStore, AuthStore]
		this.storeKeys = ['db_meeting', 'meetingName', 'color_scheme', 'jwt']
		this.state = { editing: false, current_name: '', copied: false }
		this._changeName = this._changeName.bind(this)
		this._startEditing = this._startEditing.bind(this)
		this._handleKeyPress = this._handleKeyPress.bind(this)
		this._stopEditing = this._stopEditing.bind(this)
		this.editor = null
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.editing !== this.state.editing && this.editor) {
			this.editor.focus()
			this.editor.select()
		}
	}

	copyRoomID = () => {
		navigator.clipboard.writeText(`${process.env.REACT_APP_FRONTEND_URL || 'http://localhost:1234'}/${this.state.meetingName}`)

		useToastify({
			hideProgressBar: true,
			message: <Copied><AiOutlineCheck /> Copied to clipboard</Copied>,
			position: 'top-center',
			autoClose: 1500,
			toastId: 'meetingLinkCopied',
			className: this.state.color_scheme === 'Light' ? 'toastL ' : 'toastD',
			transition: Zoom
		})
		this.setState({ copied: true })
		setTimeout(() => {
			this.setState({ copied: false })
		}, 5000);
	}

	render() {
		if (this.state.editing) {
			return (
				<Editor
					className="border-light hover-container"
					ref={(r) => (this.editor = r)}
					value={this.state.current_name}
					onBlur={this._stopEditing}
					onChange={this._changeName}
					onKeyPress={this._handleKeyPress}
				/>
			)
		}

		const presentationTitle =
			this.state.db_meeting &&
			this.state.db_meeting.bot_settings &&
			this.state.db_meeting.bot_settings.title

		const givenName = fill_string_dates(
			this.state.current_name || this.state.db_meeting.name_alias ||
			presentationTitle ||
			this.state.meetingName ||
			''
		)
		if (givenName.length === 0) {
			return null
		}

		return (
			<Wrapper data-tour="tour1-roomname">
				<Title
					className='button'
					mobile={this.props.mobile}
					onClick={this.state.db_meeting.creator_user_id === this.state.jwt.data._id ? this._startEditing : () => {}}
				// italic={!this.state.current_name && !presentationTitle}
				>
					<div className='room-title'>{`${givenName}`.trim()}</div>
					{!!!this.state.db_meeting.settings.is_public && <div className="color-primary" style={{ fontWeight: 500, fontSize: '10px', marginTop: '5px' }}>PRIVATE</div>}
				</Title>
				<CopyID className='copy-id' onClick={this.copyRoomID} mobile={this.props.mobile}>
					<div data-tip data-for='copy-link'>
						<AiOutlineLink />
					</div>
				</CopyID>
				<CustomTip top={-40} left={-25} class='top-right' place='left' tip='Copy Room Link' dataFor='copy-link' />

			</Wrapper>
		);
	}

	async _handleKeyPress(e) {
		if (e.key === 'Enter') {
			// Submit
			if(`${this.state.current_name}`.trim() === '') {
				return useToastify({
					message: () => SuccessErrorToast({ message: 'Room name is not allowed', type: 'error' }),
					position: "top-right",
					autoClose: 1500,
					closeButton: false,
					hideProgressBar: true,
					className: this.state.color_scheme === 'Light' ? 'toastL' : 'toastD',
					bodyClassName: "grow-font-size",
				})
			}
			try {
				const response = await LoopApi(null, 'ChangeMeetingName', { title: `${this.state.current_name}`.trim() })
				if(response?.success) {
					useToastify({
						message: () => SuccessErrorToast({ message: 'Successfully renamed room', type: 'success' }),
						position: "top-right",
						autoClose: 1500,
						closeButton: false,
						hideProgressBar: true,
						className: this.state.color_scheme === 'Light' ? 'toastL' : 'toastD',
						bodyClassName: "grow-font-size",
					})
					MainActions.UpdateAlias(`${this.state.current_name}`.trim())
					this.setState((prevState) => ({
						...prevState,
						db_meeting: {
							...prevState.db_meeting,
							name_alias: `${this.state.current_name}`.trim()
						}
					}))
				}
			} catch(err) {
				//console.log("error renaming room ", err)
			}
			this.setState({ editing: false })
		}
		if (e.key === 'Escape') {
			this._stopEditing()
		}
	}

	_stopEditing() {
		this.setState({
			editing: false,
			current_name: '',
		})
	}

	_startEditing() {
		this.setState({
			editing: true,
			current_name:
				this.state.db_meeting.name_alias || this.state.db_meeting.name || "",
		})
	}

	_changeName(e) {
		if (e.target.value.length > 20) return
		// debugger
		this.setState({ current_name: e.target.value })
	}
}

const Wrapper = styled.div`
	display: flex;
    align-items: center;
    max-width: 200px;
`

const Copied = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		margin-right: 5px;
	}
`

const CopyID = styled.div`
    cursor: pointer;

	@media (${(props) => (props.mobile ? 'min' : 'max')}-width: 768px) {
		display: none;
	}
`

const Editor = styled.input.attrs({ type: 'text' })`
	font-size: 16px;
	padding: 4px 10px;
	margin-left: 15px;
	border-radius: 30px;
	border: 1px solid;
`

const Title = styled.div`
	cursor: text;
	margin-left: 15px;
	font-size: 16px;
	font-weight: 400;
	text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

	.room-title {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	${(props) => props.italic && `font-style: italic;`};
	${(props) =>
		props.mobile &&
		`margin: 16px 12px;
    text-align: center;
    margin-right: 12px;`}

	@media (${(props) => (props.mobile ? 'min' : 'max')}-width: 768px) {
		display: none;
	}
`
