import React, { Component, Fragment } from 'react';
import Reflux from 'reflux';
import styled from 'styled-components';
import { MainStore, MainActions } from '../../../stores/MainStore';
import { ThemingStore } from '../../../stores/ThemingStore';
import { IoIosCloseCircle as RemoveIcon } from '@react-icons/all-files/io/IoIosCloseCircle';
import Modal from '/components/Modalv2';
import LoopApi from '/helpers/LoopApi';
import { ModalStore } from '../../../stores/ModalStore';
import useToastify from '../../../helpers/useToastify';
import SuccessErrorToast from '../../../components/Notifications/SuccessErrorToast';

export default class SetMeetingAvatar extends Reflux.Component {
  constructor(props) {
    super(props);

    this.stores = [MainStore, ThemingStore, ModalStore];
    this.storeKeys = ['db_meeting', 'color_scheme', 'cb', 'currentMeeting'];

    this.handleFile = this.handleFile.bind(this);
    this.state = {
      imagePreview: null,
      imageInfo: null,
      isUploading: false,
      db_meeting: {},
      color_scheme: 'Light',
      file: '',
      cb: () => {},
    };
  }

  handleFile(e) {
    const reader = new FileReader();
    const file = e.target.files[0];

    // if(file.type === 'image/gif')
    // return

    const imageExts = ['.jpg', '.jpeg', '.png', '.heif', '.heic'];

    const isSupported = imageExts.find((ext) => e.target.value.endsWith(ext));
    if (!!!isSupported) {
      return useToastify({
        message: () => SuccessErrorToast({ message: 'File type not supported.', type: 'error' }),
        position: 'top-right',
        autoClose: 1500,
        closeButton: false,
        hideProgressBar: true,
        className: this.state.color_scheme === 'Light' ? 'toastL' : 'toastD',
        bodyClassName: 'grow-font-size',
      });
    }

    if (file) {
      this.setState({
        file: URL.createObjectURL(file),
        imagePreview: {
          src: URL.createObjectURL(file),
          fileName: file.name,
        },
      });
    }

    reader.onload = (upload) => {
      this.setState({
        file: URL.createObjectURL(file),
        imageInfo: {
          data_uri: upload.target.result,
          filename: file.name,
          filetype: file.type,
        },
      });
    };

    reader.readAsDataURL(file);

    e.target.value = '';
  }

  upload = async (event) => {
    event.preventDefault();

    if (this.state.isUploading) {
      return;
    }

    this.setState({ isUploading: true });

    const image_info = this.state.imageInfo;

    try {
      const upload = await LoopApi(null, 'SetMeetingAvatarById', { image_info, meeting_name: this.state.currentMeeting.name }, [
        ['id', this.state.currentMeeting._id],
      ]);
      console.log({ upload });
      MainActions.SetRoomAvatar(upload.avatar_url);
      this.state.cb();
      this.props.closeModal();
    } catch (err) {
      this.props.closeModal();
      console.error(err);
    }
  };

  render() {
    const { color_scheme, currentMeeting } = this.state;

    return (
      <Modal closeModal={this.props.closeModal} dismissible={false}>
        <Container>
          <Heading>Set Room Icon</Heading>
          <SubHeading>Allowed Image Types: .jpg .png, .jpeg, .heif, .heic</SubHeading>
          {/* {this.state.imagePreview && (
						<ImagePreview>
							<img src={this.state.imagePreview.src} alt={this.state.imagePreview.fileName} />
							<span>{this.state.imagePreview.fileName}</span>
						</ImagePreview>
					)} */}

          {/* {this.state.imagePreview ? ( */}
          <div style={{ position: 'relative' }}>
            {this.state.file && !this.state.isUploading && (
              <RemoveIcon
                className="remove-icon"
                title="Remove avatar"
                onClick={() => {
                  this.setState({ file: '', imageInfo: '' });
                }}
              />
            )}
            {this.state.imageInfo?.filetype === 'image/heif' || this.state.imageInfo?.filetype === 'image/heic' ? (
              <PreviewImage
                src={`https://ui-avatars.com/api/?name=${''}&background=${
                  color_scheme === 'Light' ? 'F2F4F6' : '21212B&color=FEFEFE'
                }&format=svg`}
                alt="Room Icon"
                className="border-light"
              />
            ) : (
              <PreviewImage
                src={
                  this.state.file ||
                  currentMeeting.avatar_url ||
                  `https://ui-avatars.com/api/?name=${
                    currentMeeting.name_alias || currentMeeting.name || 'Room'
                  }&background=${color_scheme === 'Light' ? 'F2F4F6' : '21212B&color=FEFEFE'}&format=svg`
                }
                alt="Room Icon"
                className="border-light"
              />
            )}
            {/* <PreviewImage src={this.state.file || currentMeeting.avatar_url || `https://ui-avatars.com/api/?name=${currentMeeting.name_alias || currentMeeting.name || 'Room'}&background=${color_scheme === 'Light' ? 'F2F4F6' : '21212B&color=FEFEFE'}&format=svg`} alt="Room Icon" className="border-light" /> */}
          </div>
          {(this.state.imageInfo?.filetype === 'image/heif' || this.state.imageInfo?.filetype === 'image/heic') && (
            <div className="notsupported error">
              Initial image preview is not currently supported for HEIF and HEIC files
            </div>
          )}

          {/* // <Fragment>
						// 	<LinkText disabled={this.state.isUploading} onClick={this.upload}>Set Room Icon</LinkText>
						// 	<p>or</p>
						// </Fragment> */}
          {/* ) : null} */}

          <input
            type="file"
            onChange={this.handleFile}
            hidden
            name="upload"
            id="upload"
            accept="image/png, image/jpg, image/jpeg, image/heif, image/heic"
          />

          {!this.state.file ? (
            <Label for="upload" className="button primary" disabled={this.state.isUploading}>
              {this.state.isUploading ? 'Uploading' : 'Upload Image'}
            </Label>
          ) : (
            <React.Fragment>
              <Label
                className={`button primary ${!this.state.file || this.state.isUploading ? 'disabled' : ''}`}
                disabled={!this.state.file || this.state.isUploading}
                onClick={this.upload}
              >
                {this.state.isUploading ? 'Saving Room Icon' : 'Save Room Icon'}
              </Label>
            </React.Fragment>
          )}
          {/* <Label htmlFor="upload" className="button primary">Upload Image</Label> */}
          <div className="cancel-btn" onClick={() => this.props.closeModal && this.props.closeModal()}>
            Cancel
          </div>
        </Container>
      </Modal>
    );
  }
}

const Label = styled.label`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  transition: all 0.05s ease-out;
  font-weight: ${(props) => props.theme.textRegular};
  font-size: ${(props) => props.theme.textMD};
  border: none;
  margin: 4px;
  margin-top: 10px;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Container = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .remove-icon {
    position: absolute;
    font-size: 24px;
    right: 0;
    top: 0;
    opacity: 0.8;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }

  .notsupported {
    font-size: 11px;
    margin-top: 10px;
    width: 210px;
  }

  .cancel-btn {
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
    margin-top: 5px;
    padding: 10px;
    font-weight: 500;
    font-size: 13px;
  }
`;

const SubHeading = styled.div`
  margin: 16px;
  color: #888;
  font-size: 12px;
`;

const Heading = styled.h1`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
`;

const ImagePreview = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  & > img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    border: 1px solid;
    background-color: #fff;
    margin-right: 8px;
  }
`;

const LinkText = styled.p`
  padding: 8px;
  margin: 8px 0;
  text-decoration: underline;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  & ~ p {
    margin-bottom: 8px;
  }
`;

const PreviewImage = styled.img`
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
  border: 1px solid;
`;
