import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { isJSON } from '../../../helpers'

import Encryption from '/helpers/Encryption'
import LoopApi from '/helpers/LoopApi'

export default class Password extends PureComponent {
	constructor(props) {
		super(props)

		this.handleChange = this.handleChange.bind(this)

		this.state = {
			password: '',
			loading: false,
			errorMessage: null,
		}
	}

	handleChange(event) {
		this.setState({ password: event.target.value })
	}

	async handleSubmit(event) {
		event.preventDefault()
		if (!this.state.password) {
			return
		}

		let password = this.state.password
		if (
			this.props.case === 'password-ghost' ||
			this.props.case === 'password-join-ghost'
		) {
			password = Encryption.generate_session_hashes(
				password,
				this.props.match.params.meetingName
			)
		}

		this.setState({ loading: true, errorMessage: null })

		try {
			const resp = await LoopApi(
				null,
				'JoinWithPassword',
				{
					password,
				},
				[['name', this.props.match.params.meetingName]]
			)
			//console.log("RESP: ", resp)
			if (!resp.result) {
				this.setState({
					loading: false,
					errorMessage: resp.msg || `Incorrect password. ${resp.remainingTries || 0} ${resp.remainingTries > 1 ? 'attempts' : 'attempt'} remaining. Try again later.`,
				})
			} else {
				sessionStorage.ghost_meeting = this.props.match.params.meetingName
				this.props.JoinedMeeting()

				const allowedToJoin = sessionStorage.getItem('roomsAllowed')
				let parsedAllowedToJoin = isJSON(allowedToJoin) ? JSON.parse(allowedToJoin) : {}
				parsedAllowedToJoin[this.props.match.params.meetingName] = true
				parsedAllowedToJoin = JSON.stringify(parsedAllowedToJoin)
				sessionStorage.setItem('roomsAllowed', parsedAllowedToJoin)
			}
		} catch (err) {
			console.error(err)
			let message = ''
			if(err.message === 'Too many requests, please try again later.') {
				message = `Incorrect password. 0 attempt remaining. Try again later.`
			}
			this.setState({ loading: false, errorMessage: message || err.msg || err.message || null })
		}
	}

	render() {
		return (
			<LoginForm className="topbar rounded inner" onSubmit={this.handleSubmit.bind(this)}>
				<Contents>
					<Title className='header'>Room Password</Title>
					<Details className='subheader'> Enter room password to join </Details>
				</Contents>
				<FormField
					className="topbar border-light"
					data-lpignore="true"
					autocomplete="off"
					id="search-in-name-to-throw-off-pass-managers"
					type="password"
					placeholder="Password"
					value={this.state.password}
					onChange={this.handleChange}
				/>
				<SubmitButton
					className="button primary"
					disabled={this.state.loading}
					value="Enter Room"
					type="submit"
				/>
				{this.state.errorMessage && (
					<ErrorMsg className="error">{this.state.errorMessage}</ErrorMsg>
				)}
			</LoginForm>
		)
	}
}


const Contents = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  text-align: center;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.textBold};
  font-size: ${(props) => props.theme.textLG};
`;

const Details = styled.div`
  font-weight: ${(props) => props.theme.textRegular};
  font-size: ${(props) => props.theme.textMD};
  padding-top: 10px;
`;

const LoginForm = styled.form`
	/* background-color: white; */
	padding: 30px;
	/* border-radius: 4px; */
	box-shadow: ${props => props.theme.shadows.newlight};
	display: flex;
	flex-direction: column;
	min-width: 300px;
	position: relative;
	justify-content: space-around;
	max-width: 324px;
`

const FormField = styled.input`
	padding: 12px;
	font-size: 16px;
	border: 1px solid;
	margin-top: 12px;
	border-radius: 999px;
`

const SubmitButton = styled.input`
	margin-top: 12px;
	background-color: ${props => props.theme.color.rotate(90).string()};
	border: none;
	font-size: 14px;
	/* box-shadow: ${props => props.theme.shadows.light}; */
	color: white;
	padding: 9px;
	cursor: pointer;

	&:disabled {
	opacity: 0.5;
	cursor: initial;
	pointer-events: none;
	}

	&:hover {
	opacity: 0.9;
	}
`

const ErrorMsg = styled.div`
	font-size: 12px;
	text-align: center;
	margin-top: 15px;
`
