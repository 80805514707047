import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import logo from '/assets/logo.svg';
import logowhite from '/assets/logowhite.svg';

class Header extends Component {
  render() {
    const { withButtons = true, white = false } = this.props;
    return (
      <Container>
        <LogoGroup onClick={() => window.open('https://grapl.it', '_blank')}>
          <Logo alt="Grapl" src={white ? logowhite : logo} />
        </LogoGroup>
        {withButtons ? (
          <SignupGroup>
            <span className="account-help">
              {this.props.login ? 'Already have an account?' : `Don't have an account?`}
            </span>
            <Button
              onClick={() =>
                this.props.login && this.props.customLogin
                  ? this.props.customLogin()
                  : this.props.history.push(
                      this.props.login ? `/login` : `/fullsignup/${this.props.match.params.meetingName || ''}`,
                    )
              }
            >
              {this.props.login ? 'Log In' : 'Sign Up'}
            </Button>
          </SignupGroup>
        ) : (
          ''
        )}
      </Container>
    );
  }
}

export default withRouter(Header);

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  max-height: 72px;
  height: 72px;
  padding: 15px 30px;
  font-size: 14px;
  /* border-bottom: 1px solid #E9ECEF; */
`;

const LogoGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Logo = styled.img`
  width: 140px;
`;

const SignupGroup = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  span {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    margin: 0 15px;
  }
`;

const Button = styled.div`
  /* background: #0969da; */
  background: #008bff;
  border-radius: 999px;
  padding: 10px 40px;
  color: white;
  font-weight: 400;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
`;
