import React, { createRef, Fragment } from 'react'
import Reflux from 'reflux'
import Modal from '/components/Modalv2'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import { MainStore } from '/stores/MainStore'
import { FaUsers as TeamIcon } from '@react-icons/all-files/fa/FaUsers'
import { MdEmail as Email } from '@react-icons/all-files/md/MdEmail'
import { MdContentCopy as Copy } from '@react-icons/all-files/md/MdContentCopy'
import { MdCheck as Checked } from '@react-icons/all-files/md/MdCheck'
import { FiSend as SendIcon } from '@react-icons/all-files/fi/FiSend'
import { FiSearch as SearchIcon } from '@react-icons/all-files/fi/FiSearch'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiLink as LinkIcon } from '@react-icons/all-files/fi/FiLink'
import { AiOutlineCheck } from '@react-icons/all-files/ai/AiOutlineCheck'

import LoopApi from '/helpers/LoopApi'
import useToastify from '../../../helpers/useToastify'
import { WidgetActions } from '../../../stores/MainStore'
import { AuthStore } from '../../../stores/AuthStore'
import { VariableCallingStore } from '../../../stores/VariableCallingStore'
import { ModalActions } from '../../../stores/ModalStore'
import { ImUser } from 'react-icons/im'

import InputGroupItems from '../../../components/InputGroupItems'
import { ThemingStore } from '../../../stores/ThemingStore'
import SuccessErrorToast from '../../../components/Notifications/SuccessErrorToast'
import Checkbox from '../../../components/Checkbox'
import { Zoom } from 'react-toastify'
import Scrollbars from 'react-custom-scrollbars'
import { GlobalState } from 'reflux'

// BE email regex
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default class PublicLink extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            db_meeting: {},
            users: [],
            email: '',
            sent: false,
            copied: false,
            emails: [],
            loading: false,
            error_msg: null,
            color_scheme: 'Light',
            usersToDel: [],
            selectAll: false,
            showSelected: false,
            activeSearchbar: false,
            searchValue: ''
        }

        this.timer = null
        this.stores = [MainStore, AuthStore, VariableCallingStore, ThemingStore]
        this.storeKeys = ['db_meeting', 'users', 'jwt', 'status', 'connectedUserIds', 'color_scheme']
        this.handleCopyButton = this.handleCopyButton.bind(this)
        this.addToMeeting = this.addToMeeting.bind(this)
        this.removeFromMeeting = this.removeFromMeeting.bind(this)
        this.InputGroupItemsRef = createRef()
        this.searchRef = createRef()
    }

    async handleSubmit(event) {
        event.preventDefault()
        try {
            const resp = await LoopApi(
                null,
                'InviteToMeeting',
                { email: this.state.email },
                [['name', this.state.db_meeting.name]]
            )
            //console.log(resp)
            this.setState({ sent: true })
        } catch (e) {
            console.error(e)
        }
    }

    handleCopyButton() {
        // this.setState({ copied: true })
        // this.timer = setTimeout(() => {
        //     this.setState({ copied: false })
        //     clearTimeout(this.timer)
        // }, 3000);
        useToastify({
			hideProgressBar: true,
			message: <Copied><AiOutlineCheck /> Copied to clipboard</Copied>,
			position: 'top-center',
			autoClose: 1500,
			toastId: 'meetingLinkCopied',
			className: (this.state.color_scheme || 'Light') === 'Light' ? 'toastL ' : 'toastD',
			transition: Zoom
		})
    }

    toggleShowSelected = () => {
        this.setState((prevState) => ({ ...prevState, showSelected: !this.state.showSelected }))
    }

    addToMeeting() {
        let emails = [...this.state.emails, this.InputGroupItemsRef.current.getInput()]
        const myEmail = GlobalState.auth.jwt.data.email
        // remove my email in emails
        emails.mails = emails.filter((email) => email !== myEmail)
        // filter out emails that are already in the this.state.users
        emails.mails = emails.mails.filter((email) => !this.state.users.find((user) => user.email === email))

        const sendInvite = async () => {
            const { mails } = emails
            this.setState({ loading: true })
            try {
                const resp = await LoopApi(null, 'SendEmailInvite', {
                    emails: mails.join().trim()
                },
                    [['name', db_meeting.name]]
                )

                if (resp.success) {
                    useToastify({
                        message: () => SuccessErrorToast({ message: 'Successfully sent invitation email(s).' }),
                        position: "top-right",
                        autoClose: 1500,
                        closeButton: false,
                        hideProgressBar: true,
                        className: this.state.color_scheme === 'Light' ? 'toastL' : 'toastD',
                        bodyClassName: "grow-font-size",

                    })
                    this.setState({ loading: false, emails: [], error_msg: null })
                }

            } catch (e) {
                if(this.state.emails.length === 1){
                    this.setState({ loading: false, error_msg: e.msg || 'Cannot invite existing email.' })
                } else {
                    this.setState({ loading: false, error_msg: 'Error sending the invite (Emails might be existing or having error inviting that email)' })
                }
            }
        }

        const { db_meeting } = this.state
        const input = this.InputGroupItemsRef.current.getInput()

        if (!emails?.mails?.join()?.trim() && !!!input) {
            this.setState({ error_msg: 'Please enter at least one email.' })
            this.InputGroupItemsRef.current.setInput('');
            return
        }

        if (input.length) {
            if (EMAIL_REGEX.test(input)) {
                this.setState({ emails: [...emails, input] }, () => sendInvite())
                this.InputGroupItemsRef.current.setInput('');
            } else {
                this.setState({ error_msg: 'Invalid email format.' })
                this.InputGroupItemsRef.current.setInput('');
                return
            }
        } else {
            sendInvite()
        }
    }

    async removeFromMeeting(user_id, guest = false) {
        this.setState({ loading: true })
        try {
            const resp = await LoopApi(null, 'RemoveUserFromMeeting', {
                user_id,
                guest,
                show_modal: true,
                meeting_name: this.state.db_meeting.name
            })

            if (!resp.error) {
                useToastify({
                    message: () => SuccessErrorToast({ message: 'Successfully removed user.' }),
                    position: "top-right",
                    autoClose: 1500,
                    closeButton: false,
                    hideProgressBar: true,
                    className: this.state.color_scheme === 'Light' ? 'toastL' : 'toastD',
                    bodyClassName: "grow-font-size",

                })
                WidgetActions.SetUsers(this.state.users.filter(user => user.id !== user_id))
                this.setState({ loading: false, emails: [], error_msg: null })
            }
        } catch (e) {
            console.error(e)
            this.setState({ loading: false, error_msg: e.msg })
        }
    }

    removeUsersFromMeeting = async (ids) => {
        this.setState({ loading: true })
        try {
            const resp = await LoopApi(null, 'RemoveUsersFromMeeting', {
                ids
            })

            if (!resp.error) {

                //Remove user to chat
                this.removeUserToChat(ids)
                    .then(() => this.informChat(ids))
                    .then(() =>
                        useToastify({
                            message: () => SuccessErrorToast({ message: 'Successfully removed user.' }),
                            position: "top-right",
                            autoClose: 1500,
                            closeButton: false,
                            hideProgressBar: true,
                            className: this.state.color_scheme === 'Light' ? 'toastL' : 'toastD',
                            bodyClassName: "grow-font-size",

                        })
                    )

                const userids = ids.map(id => id.user_id)
                WidgetActions.SetUsers(this.state.users.filter(user => !userids.includes(user.id)))
                this.setState({ loading: false, emails: [], error_msg: null })
            }
        } catch (e) {
            this.setState({ loading: false, error_msg: e.msg })
        }
    }

    removeUserToChat = (ids) => {

        return new Promise((res, err) => {
            //@ts-ignore
            var doc = window.shareDbConnection.get('chats', this.state.db_meeting.name)
            doc.subscribe((error) => {
                if (error) return console.error(error)

                if (doc.type) {
                    const chatData = doc.data
                    let ops = []
                    ids.map((id) => ops.push({ p: ['users', chatData.users.indexOf(id.user_id)], ld: id.user_id }))
                    console.log({ ops });
                    doc.submitOp(ops, {}, (error) => {
                        doc.unsubscribe()
                        if (error) {
                            err()
                        } else {
                            res()
                        }
                    })
                }
            })
        })
    }

    informChat = (ids) => {
        let ObjectId = (m = Math, d = Date, h = 16, s = (s) => m.floor(s).toString(h)) =>
            s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))

        const myInfo = this.state.users.find((user) => user.id === this.state.jwt.data._id)
        let users = []
        ids.map((id) => users.push(this.state.users.find((user) => user.id === id.user_id).name))

        // const message = ids.length > 1 ?
        //     `${users.map((user, index) => users.length - 1 === index ? `and ${user}` : `${user}, `)}`
        //     :
        //     `${users[0]}`

        const message = `${users.map((user, index) => `${myInfo.name} has removed ${user} from this conversation.\n\n`)}`

        return new Promise((res, err) => {
            //@ts-ignore
            var fileDoc = window.shareDbConnection.get('chat-contents', ObjectId())
            const timeStamp = new Date().getTime()

            fileDoc.create({
                receiver: this.state.db_meeting.name,
                meetingURL: this.state.db_meeting.name,
                sender: {
                    avatar: myInfo.avatar_url || '',
                    name: myInfo.name,
                    id: this.state.jwt.data._id
                },
                type: 'info',
                message,
                timeStamp,
            }, (error) => {
                if (error) {
                    console.error(error)
                    err()
                } else {
                    res()
                }
            })
        })
    }

    handleSelect = (checked, id) => {
        let ids = []
        if (checked) {
            ids = [...this.state.usersToDel, id]
            this.setState({ usersToDel: ids })
        } else {
            ids = this.state.usersToDel.filter(u => u.user_id !== id.user_id)
            this.setState({ usersToDel: ids })
        }
    }

    toggleSelectAll = (checked, usersInCall) => {
        if (checked) {
            let users = this.state.users.filter((user => {
                if (
                    this.state.db_meeting.creator_user_id === user.id ||
                    (usersInCall.length && (usersInCall || []).includes(user.id))
                )
                    return false

                return true
            }))

            if (users.length) {
                users = users.map(u => {
                    return { user_id: u.id, guest: u.type === 'guest' }
                })
            }

            this.setState({ usersToDel: users, selectAll: true })
        } else {
            this.setState({ usersToDel: [], selectAll: false })
        }

    }

    removeUsers = () => {
        ModalActions.SetModalWithParams('RemoveUser', {
            cb: () => this.removeUsersFromMeeting(this.state.usersToDel)
        })
    }

    render() {
        const fully_private =
            this.state.db_meeting.settings &&
            !this.state.db_meeting.settings.allow_knocking &&
            !this.state.db_meeting.settings.is_public

        let inviteMethod
        inviteMethod = (
            <div>
                <HeaderContainer>
                    <Title className="header">Manage Room Users</Title>
                    <SubTitle className="subheader">Invite your team and collaborate</SubTitle>
                </HeaderContainer>
                <LinkContainer>
                    {/* <InputContainer className="border-bottom">
                        <LinkInput
                            className="topbar-modal"
                            readOnly
                            type="text"
                            value={`${window.location.origin}/${this.props.meetingName}`}
                        />
                        <CopyClip disabled={fully_private} text={`${window.location.origin}/${this.props.meetingName}`}
                            onCopy={fully_private ? () => { } : () => this.handleCopyButton()}>
                            <Button>
                                {
                                    this.state.copied ? <React.Fragment>
                                        <Checked title="copied" /> Copied
                                    </React.Fragment> : <React.Fragment>
                                        <Copy title="copy" /> Copy
                                    </React.Fragment>
                                }
                            </Button>
                        </CopyClip>
                    </InputContainer> */}
                    {
                        this.state.jwt.data.type === 'account' &&
                        <InputContainer>
                            <InputGroupItems
                                setError={(errorMessage) => { this.setState({ error_msg: errorMessage }) }}
                                restriction={(toTest) => EMAIL_REGEX.test(toTest)}
                                Icon={ImUser}
                                disabled={this.state.loading || this.state.jwt.data.type === 'guest' || fully_private}
                                placeholder="Enter email address then press spacebar"
                                setItems={(emails) => { this.setState({ emails: emails }, () => this.InputGroupItemsRef.current.scrollToBottom()) }}
                                items={this.state.emails}
                                name="emails"
                                ref={this.InputGroupItemsRef}
                                maxHeight={'75px'}
                            />
                            <Send>
                                <button
                                    className="button primary"
                                    onClick={this.addToMeeting}
                                    disabled={this.state.loading || this.state.jwt.data.type === 'guest' || fully_private}>
                                    Invite {this.state.emails.length > 1 ? 'Users' : ''}
                                </button>
                            </Send>
                        </InputContainer>
                    }
                </LinkContainer>
            </div>
        )

        // const usersInCall =
        const usersInCallKey = this.state.connectedUserIds.length === undefined ? (Object.keys(this.state.connectedUserIds) || []).filter((key) => !isNaN(parseInt(key))) : this.state.connectedUserIds
        const usersInCall = usersInCallKey.map((u) => this.state.connectedUserIds[u])

        const usersToDelIds = this.state.usersToDel.map(u => u.user_id)

        let users = (this.state.users || [])
            .sort((x, y) => { return x.id === this.state.db_meeting.creator_user_id ? -1 : y.id === this.state.db_meeting.creator_user_id ? 1 : 0; })
            

        let newusers = users

        if(this.state.showSelected) {
            console.log({ usersToDelIds, users })
            newusers = users.filter(u => usersToDelIds.includes(u.id))
        } else {
            newusers = users
        }

        const ownerData = newusers
                            .filter(u => this.state.db_meeting.creator_user_id === u.id)
                            .map((user) => {
                                const disableRemove = usersInCall.length && (usersInCall || []).includes(user.id)
                    
                                return <UserWrapper className="border-light" data-id={user.id} key={user.id}>
                                    <div className="user-image-wrapper">
                                        <img src={user.avatar_url} alt={user.name} />
                                    </div>
                                    <div className="user-data-wrapper">
                                        <div className="username" >{user.name}</div>
                                        <div className="user-type" >{user.type === 'guest' ? 'Guest user' : 'Account user'}</div>
                                        <div className="user-type">{user.email || ''}</div>
                                        {this.state.db_meeting.creator_user_id === user.id ? <div className="user-type color-primary">Creator</div> : ''}
                                    </div>
                                    <div className="user-action">
                                        {
                                            this.state.db_meeting.creator_user_id !== user.id &&
                                            this.state.jwt.data._id === this.state.db_meeting.creator_user_id &&
                                            <Checkbox
                                                disabled={disableRemove}
                                                checked={usersToDelIds.includes(user.id)}
                                                onChange={(e) => this.handleSelect(e.target.checked, { user_id: user.id, guest: user.type === 'guest' })}
                                            />
                                        }
                                    </div>
                                </UserWrapper>
                            })

        newusers = newusers.filter(u => this.state.db_meeting.creator_user_id !== u.id)
        newusers = newusers.filter(u => {
            const n = u.name ? u.name.toLowerCase() : ''
            const s = `${this.state.searchValue}`.toLowerCase()
            const e = u.email ? u.email.toLowerCase() : ''
            return (n.includes(s) || e.includes(s))
        }).map((user) => {
            const disableRemove = usersInCall.length && (usersInCall || []).includes(user.id)

            return <UserWrapper className="border-light" data-id={user.id} key={user.id}>
                <div className="user-image-wrapper">
                    <img src={user.avatar_url} alt={user.name} />
                </div>
                <div className="user-data-wrapper">
                    <div className="username" >{user.name}</div>
                    <div className="user-type" >{user.type === 'guest' ? 'Guest user' : 'Account user'}</div>
                    <div className="user-type">{user.email || ''}</div>
                    {this.state.db_meeting.creator_user_id === user.id ? <div className="user-type color-primary">Creator</div> : ''}
                </div>
                <div className="user-action">
                    {
                        this.state.db_meeting.creator_user_id !== user.id &&
                        this.state.jwt.data._id === this.state.db_meeting.creator_user_id &&
                        <Checkbox
                            disabled={disableRemove}
                            checked={usersToDelIds.includes(user.id)}
                            onChange={(e) => this.handleSelect(e.target.checked, { user_id: user.id, guest: user.type === 'guest' })}
                        />
                    }
                </div>
            </UserWrapper>
        })

        //console.log("USERS: ", this.state.users)

        return (
            <Modal closeModal={this.props.closeModal} contentStyle={{ overflowY: 'hidden' }}>
                <Container>
                    {inviteMethod}
                    {/* <LinkContainer paddingTop>
                        <Button
                            className="button primary hoverEffect"
                            onClick={() => this.props.openOtherModal('AddViaEmail')}
                        >
                            <Email size={20} title="email" />Invite Users via Email
                        </Button>
                        {this.state.db_meeting.team_id && (
                            <Button
                                className="button default hoverEffect"
                                onClick={() => this.props.openOtherModal('AddFromTeam')}
                            >
                                <TeamIcon size={20} />Add Users From Team
                            </Button>
                        )}
                    </LinkContainer> */}
                    {/* {
                        this.state.jwt.data._id === this.state.db_meeting.creator_user_id &&
                        <MultipleSelect disabled={!this.state.usersToDel.length}>
                            <div className="select-all-check">
                                <Checkbox
                                    checked={this.state.selectAll}
                                    onChange={(e) => this.toggleSelectAll(e.target.checked, usersInCall)}
                                />
                            </div>
                            <div>All({this.state.usersToDel.length})</div>
                            {
                                usersToDelIds.length ?
                                    <div>
                                        <button
                                            className="all-select-del button default"
                                            onClick={this.removeUsers}
                                        >Remove</button>
                                    </div> : ''
                            }
                        </MultipleSelect>
                    } */}
                    <div>{ownerData}</div>
                    <SearchbarWrapper>
                        <div class="searchbar">
                            <div class={`icon active`}>
                                <SearchIcon />
                            </div>
                            <input 
                                ref={this.searchRef}
                                type="text" 
                                placeholder="Search by name or email" 
                                className={`active color-textimp topbar-modal`}
                                onChange={(e) => this.setState({ searchValue: e.target.value })}
                                value={this.state.searchValue || ''}
                            />
                            
                        </div>
                    </SearchbarWrapper>
                    <UserCollection>
                        <Scrollbars
                            style={{ height: '100%'}}
                            autoHide
                            autoHideTimeout={1000}
                            autoHideDuration={200}
                            renderThumbHorizontal={props => <div {...props} className="custom-scroll-thumb" />}
                            renderThumbVertical={props => <div {...props} className="custom-scroll-thumb" />}
                        >
                            {newusers}
                        </Scrollbars>
                    </UserCollection>
                    <FooterWrapper className='border-light'>
                        <CopyClip disabled={fully_private} text={`${window.location.origin}/${this.props.meetingName}`}
                            onCopy={fully_private ? () => { } : () => this.handleCopyButton()} className="color-primary">
                            <StyledButton className="color-primary">
                                {
                                    this.state.copied ? <React.Fragment>
                                        <Checked title="copied" /> Copied
                                    </React.Fragment> : <React.Fragment>
                                        <LinkIcon title="copy" /> Copy Room Link
                                    </React.Fragment>
                                }
                            </StyledButton>
                        </CopyClip>
                        {
                            this.state.jwt.data._id === this.state.db_meeting.creator_user_id ?
                            <React.Fragment>
                                <div className='footer-actions'>
                                    <div className={!!!usersToDelIds.length ? 'disabled' : ''} onClick={usersToDelIds.length ? () => this.toggleShowSelected() : () => {}}>Show {this.state.showSelected ? 'all' : `selected (${this.state.usersToDel.length})`}</div> 
                                    <div onClick={() => this.toggleSelectAll(!!!this.state.selectAll, usersInCall)}>{!!!this.state.selectAll ? 'Select' : 'Unselect'} All</div>
                                    <div onClick={usersToDelIds.length ? () => this.removeUsers() : () => {}} className={`${!!!usersToDelIds.length ? 'disabled' : ''} danger error`}>Remove</div>
                                </div>
                            </React.Fragment> : ''
                        }
                    </FooterWrapper>
                        
                    {!!this.state.error_msg && <ErrorMessage>{this.state.error_msg}</ErrorMessage>}
                </Container>
            </Modal>
        )
    }
}

const SearchbarWrapper = styled.div`
    padding: 0 10px 0 0;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;

    .searchbar{
        position: relative;
    }

    .searchbar input[type="text"]{
        border: 0;
        padding: 0;
        width: 0px;
        height: 40px;
        border-radius: 3px;
        transition: all 0.3s ease;
        font-size: 13px;

        &::-webkit-input-placeholder {
            opacity: 1 !important;
            font-size: 12px;
        }

        &::placeholder {
            opacity: 1 !important;
            font-size: 12px;
        }
    }

    .searchbar .icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        border-radius: 30px;
        transition: all 0.5s 0.3s ease;
        cursor: pointer;
        /* border: 1px solid #E9ECEF; */
    }

    .searchbar .icon svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        cursor: pointer;
    }

    .searchbar .icon.active{
        transition: all 0.3s ease;
        border: none;
    }

    .searchbar input[type="text"].active{
        width: 200px;
        padding: 0 15px 0 40px;
        transition: all 0.3s 0.2s ease;
        /* border: 1px solid #E9ECEF; */
        border-radius: 30px;
    }
`

const FooterWrapper = styled.div`
    display: flex;
    font-size: 12px;
    font-weight: 400;
    border-top: 1px solid;
    margin-top: 20px;

    .footer-actions {
        flex: 1;
        justify-content: space-evenly;
        align-items: center;
        display: flex;

        > div:not(.disabled) {
            cursor: pointer;
        }
        
        > div.disabled {
            opacity: 0.3;
        }
    }
`

const CopyClip = styled(CopyToClipboard)`
    ${props => props.disabled && `
        pointer-events: none;
        opacity: 0.3;
    `}

    display: flex;
`

const UserCollection = styled.div`
	max-height: 200px;
	overflow-y: auto;
    height: 362px;
`

const UserWrapper = styled.div`
	display: flex;
	padding: 10px 0;
    /* border-bottom: 1px solid; */
    padding-right: 20px;

    .user-select {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        width: 25px;
    }

	.user-image-wrapper {
		width: 50px;
		height: 50px;

		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
            object-fit: cover;
		}
	}

	.user-data-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		margin-left: 15px;
        flex: 1;
        // margin-right: 15px;

		.username {
			font-weight: 500;
			font-size: 14px;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 16px;
		}

		.user-type {
			margin-top: 5px;
			font-size: 12px;
			opacity: 0.8;
		}
	}

    .user-action {
		display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;

        > * {
            font-weight: 500;
        }

        .remove-user {
            cursor: pointer;
        }

        .disabled.remove-user {
            opacity: 0.4;
            cursor: not-allowed;
        }
    }
`

const InputWithButton = styled.div`
    /* width: 100%; */
    /* padding: 5px 10px; */
	margin-top: 30px;
    border-radius: 999px;
    border: 1px solid;
    display: flex;

    input {
        flex: 1;
        border-radius: 999px;
        padding: 12px 16px;
        font-size: 14px;
		border: none;
        background: none;

        &::placeholder {
            opacity: 0.5;
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    button {
        background: none;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 15px !important;
        cursor: pointer;
		font-size: 18px;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.2;
        }
    }
`

const Container = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 500px;
    /* align-items: center; */
`

const HeaderContainer = styled.div`
    /* min-width: 400px; */
    display: flex;
    flex-direction: column;
    padding: 0px 20px 20px;
`

const Title = styled.div`
    font-weight: ${props => props.theme.textBold};
    font-size: ${props => props.theme.textLG};
`

const SubTitle = styled.div`
    font-weight: ${props => props.theme.textRegular};
    font-size: ${props => props.theme.textMD};
    padding-top: 10px;
`

const Button = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 14px;
    margin: 4px 0px;
    transition: box-shadow 0.3s ease-out;

    &.hoverEffect:hover {
        box-shadow: ${props => props.theme.shadows.light};
    }

    svg {
        margin-right: 2px;
    }

`

const StyledButton = styled.div`
    cursor: pointer;
    /* flex: 1; */
    padding: 14px;
    margin: 4px 0px;
    font-weight: 500;
    transition: box-shadow 0.3s ease-out;

    &.hoverEffect:hover {
        box-shadow: ${props => props.theme.shadows.light};
    }

    svg {
        margin-right: 2px;
    }

`

const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* padding: 0px 20px; */

    margin-bottom: 10px;

    ${(props) => props.paddingTop ? 'padding-top: 10px;' : ''}
`

const InputContainer = styled.div`
    display: flex;
`

const LinkInput = styled.input`
    cursor: text;
    width: 100%;
    padding: 12px;
    background: none;
    font-size: ${props => props.theme.textMD} !important;
    font-weight: ${props => props.theme.textBold} !important;
    border: none;
    user-select: all;
`

const ErrorMessage = styled.div`
    margin-top: 11px;
    color: red;
    font-size: 0.8em;
`

const Send = styled.div`
    cursor: pointer;

    button {
        min-width: 100px;
        border: none;
        /* margin-top: 7px; */
        cursor: pointer;
    }
`

const Copied = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		margin-right: 5px;
	}
`


const MultipleSelect = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    > div {
        margin-right: 8px;
    }

    .all-select-del {
        border: none;
        padding: 8px 12px !important;
        ${({ disabled }) => disabled ?
        'cursor: default; pointer-events: none;' :
        'cursor: pointer; pointer-events: auto;'
    };
    }
`