/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable default-case */

export const toStartOfDay = (date) => {
	const newDate = new Date(date)
  newDate.setHours(0)
  newDate.setMinutes(0)
  newDate.setSeconds(0)
  newDate.setMilliseconds(0)

  return newDate
}


// This can filter invalid envents and missing prpos or events that can't be parsed to contain valid to/from dates
export const parseEvents = (events) => {
  return events.map((__event) => {
    let event_date_from = __event.dateFrom
    let event_date_to = __event.dateTo

    let fromIndex = event_date_from.toString().indexOf('+');
    if (fromIndex) {
      event_date_from = event_date_from.split('+')[0]
    }

    let toIndex = event_date_to.toString().indexOf('+')
    if (toIndex) {
      event_date_to = event_date_to.split('+')[0]
    }

    var dateFrom = event_date_from.slice(0, 10) + 'T' + event_date_from.slice(11);
    var dateTo = event_date_to.slice(0, 10) + 'T' + event_date_to.slice(11);

    const from =  new Date(dateFrom)
    const to =  new Date(dateTo)

    return {
      ...__event,
      from,
      to
    }
  })
}

export const isSpecialCharacter = (__value) => {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(__value)
}

export const findEventsForDate = (events, date) => {
	const dateTime = date.getTime()
  var curr_date = `${date.getFullYear()}-${(date.getMonth()+1)}-${date.getDate()}`

  return events.filter((event) => {
    const event_from_time = toStartOfDay(event.from).getTime()
    const event_to_time = toStartOfDay(event.to).getTime()

    // don't display if end time is 12MN sharp
    var end_date = `${event.to.getFullYear()}-${(event.to.getMonth()+1)}-${event.to.getDate()}`
    let end_time_12mn = event.to.getHours() === 0 && event.to.getMinutes() === 0 ? true : false

    return (end_date === curr_date && end_time_12mn) ? false : (dateTime >= event_from_time && dateTime <= event_to_time)
  })
}

export const getInWeekRange = (data) => {
  // Get the Sunday to Saturday within the week
  // based on the `date`
  const { date } = data
  let current_day = date.getDay()
  let week_start = date

  // get sunday's date
  week_start.setDate(week_start.getDate() - (current_day-1))
  let dateFrom = week_start.toISOString()

  // get saturday's date
  week_start.setDate(week_start.getDate() + 6)
  let dateTo = week_start.toISOString()

  return { dateFrom, dateTo }
}


export const __changeTimezone = (__date, __tz) => {
    var start_utc_date = new Date(Date.UTC(__date.getFullYear(), __date.getMonth(), __date.getDate(), __date.getHours(), __date.getMinutes(), __date.getSeconds()));
    return start_utc_date.toLocaleString('en-US', { timeZone: __tz.toString() });
}

export const __formatDate = (__date, __add_z = false) => {
    function addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }

    let _date = new Date(__date)
    let day = addZero(_date.getDate())
    let month = addZero(_date.getMonth() + 1)
    let year = _date.getFullYear()

    let dateString = `${year}-${month}-${day}T${addZero(_date.getHours())}:${addZero(_date.getMinutes())}:00${__add_z?'Z':''}`
    return dateString
}


export const getCalendarDateRange = (_calendar_date) => {
  let new_date = new Date(_calendar_date.getFullYear(), _calendar_date.getMonth(), 1)
  let min_date = new Date(new_date.getFullYear(), new_date.getMonth(), 0)
  min_date.setDate(1)

  var max_date = new Date(new_date.getFullYear(), new_date.getMonth() + 2, 1)
  return {
    min: min_date,
    max: max_date
  }
}

/**
 *
 * @param {array} filteredMembers members that you user can see or select to view in filter sidebar
 * @param {array} attendees members or guests invited in the event
 * @returns
 */
export const filterPrivateMembers = (filteredMembers = [], attendees = []) => {
  return attendees.filter(a => { return filteredMembers.find((m) => { return m.email && m.email.toString() === a.email && a.email.toString() } ) ? true : false })
}

/**
 *
 * @param {array} event
 * @param {date} gridDate current grid date where the event is being shown
 * @returns
 */
export const filterDateFrom = (event, gridDate) => {
  const { dateFrom, dateTo } = event

  const grid_date = new Date(gridDate)
  const start_date = new Date(dateFrom)
  const end_date = new Date(dateTo)

  if (start_date.getDate() < end_date.getDate()) {
    if (grid_date.getDate() > start_date.getDate() && grid_date.getDate() <= end_date.getDate()) {
      return toStartOfDay(grid_date)
    }
  }

  return dateFrom
}

export const setTimeBy = (datetime, by = 30) => {
  let currTime = new Date(datetime)

  switch(by) {
    case 15:
      if (currTime.getMinutes() <= 15)  {
        currTime.setMinutes(15)
      }
      else if (currTime.getMinutes() > 15 && currTime.getMinutes() <= 30) {
        currTime.setMinutes(30)
      }
      else if (currTime.getMinutes() > 30 && currTime.getMinutes() <= 45) {
        currTime.setMinutes(45)
      }
      else {
        currTime.setHours(currTime.getHours() + 1)
        currTime.setMinutes(0)
      }
      break
    case 30:
      if (currTime.getMinutes() <= 30) {
        currTime.setMinutes(30)
      }
      else {
        currTime.setHours(currTime.getHours() + 1)
        currTime.setMinutes(0)
      }
      break

    default:
      currTime.setHours(currTime.getHours() + 1)
      currTime.setMinutes(0)
      break
  }

  return currTime
}


export const isGraplMeetingLink = (url) => {
  if (!url) return false

  const meeting_url_format = /(\b(http|https?):\/\/)|(\b(grapl.it|app.grapl.it|localhost:[0-9]|staging.grapl.it)*)((([a-z]{4})-([0-9]{4})-([a-z]{4}))*)/g
  const matches = url.match(meeting_url_format)
  if (matches && matches.length) {
    let found = []
    matches.forEach(val => {
      if (['http://', 'https://'].indexOf(val) !== -1)
        found.push({ kind: 'protocol', value: val, order: 1 })
      else if (['grapl.it', 'app.grapl.it', 'localhost:1234', 'staging.grapl.it'].indexOf(val) !== -1)
        found.push({ kind: 'domain', value: val, order: 2 })
      else if (val.match(/^([a-z]{4})-([0-9]{4})-([a-z]{4})$/g))
        found.push({ kind: 'id', value: val, order: 3 })
    })

    let checkId = found.find(f => f.kind === 'id')
    if (!checkId) {
      return false
    }

    // console.log('isGraplMeetingLink: ',matches, found, url)
    return found.length === 3 ? found : false
  }

  return false
}

export const isValidUrl = (url) => {
  try {
    new URL(url);
  } catch (e) {
    // console.error(e);
    return false;
  }
  return true;
};