export const lightTheme = {
  primaryBackground: '#FFFFFF',
  primaryBackgroundShadow: '0 24px 64px #26214a1a',
  primaryBackgroundDarkShadow: 'rgb(0 0 0 / 10%) 0px 0px 15px',
  secondaryBackground: '#F8F9FB',
  tertiaryBackground: '#E9ECF1',
  colorBorderOverPrimaryBackground: '#e4e4e4',
  colorText: '#363B45',
  colorTextLight: '#4c5362',
  colorTextLighter: '#8c8c8c',
  colorPrimary: '#FF9900',
  colorPrimaryLighter: '#ffe1b4',
  colorPrimaryOpaque: 'rgba(255, 153, 0, 0.2)',
  colorPrimaryGradient: 'linear-gradient(90deg, #FF8200 0%, #FF9900 100%)',
  colorPrimaryGradientLight: 'linear-gradient(90deg, #ff9b33 0%, #ffad33 100%)',
  colorPrimaryShadow: 'rgb(255 195 105 / 25%)',
  colorSecondary: '#1890ff',
  colorSecondaryLight: '#40a9ff',
}

export const darkTheme = {
  primaryBackground: '#222222',
  primaryBackgroundShadow: '0 24px 64px #26214a1a',
  secondaryBackground: '#F8F9FB',
  tertiaryBackground: '#E9ECF1',
  colorBorderOverPrimaryBackground: '#e4e4e4',
  colorText: '#ffffff',
  colorTextLight: '#4c5362',
  colorTextLighter: '#8c8c8c',
  colorPrimary: '#40a9ff',
  colorPrimaryLighter: '#ffe1b4',
  colorPrimaryOpaque: 'rgba(255, 153, 0, 0.2)',
  colorPrimaryGradient: 'linear-gradient(90deg, #FF8200 0%, #FF9900 100%)',
  colorPrimaryGradientLight: 'linear-gradient(90deg, #ff9b33 0%, #ffad33 100%)',
  colorPrimaryShadow: 'rgb(24 144 255 / 20%)',
  colorSecondary: '#1890ff',
  colorSecondaryLight: '#40a9ff',
}


export default {
  theme: lightTheme
}